import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "../../api/axiosInstance";


export const fetchDataExams = createAsyncThunk("api/fetchDataExams", async () => {
  try {
    const response = await axiosInstace.get("/test"); 
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const createDataExams = createAsyncThunk(
  "api/createDataExams",
  async (createDataExams) => {
    try {
      const response = await axiosInstace.post("/test/addTest", createDataExams);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const updateDataExams = createAsyncThunk(
  "api/updateTest",
  async ({ id, updatedData }) => {
    try {
      const response = await axiosInstace.put(`/test/updateTest/${id}`, updatedData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteDataExams = createAsyncThunk(
  "apiQuestion/deleteDataExams",
  async (itemId, thunkAPI) => {
    try {
      await axiosInstace.delete(`/test/deleteTest/${itemId}`);
      return itemId;
    } catch (error) {
      throw new Error(error.response.data.message);

    }
  }
);

const apiExamsSlice = createSlice({
  name: "apiExams",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataExams.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataExams.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataExams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createDataExams.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDataExams.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createDataExams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDataExams.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDataExams.fulfilled, (state, action) => {
        const updatedData = action.payload;
        const index = state.data.findIndex((item) => item.id === updatedData.id);
        if (index !== -1) {
          state.data[index] = updatedData;
        }
        state.loading = false;
      })
      .addCase(updateDataExams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDataExams.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDataExams.fulfilled, (state, action) => {
        const deletedItemId = action.payload;
        state.data = state.data.filter((item) => item.id !== deletedItemId);
        state.loading = false;
      })
      .addCase(deleteDataExams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default apiExamsSlice.reducer;