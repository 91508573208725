import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const addDataCategory = createAsyncThunk(
  "addModal/addDataCategory",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/categories/addCategories", data); 
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addDataExams = createAsyncThunk(
  "addModal/addDataExams",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/test/addTest", data); 
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addModalSlice = createSlice({
  name: "addModal",
  initialState: {
    isOpen: false,
    error: null,
  },
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
      state.error = null;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addDataCategory.pending, (state) => {
        state.error = null;
      })
      .addCase(addDataCategory.fulfilled, (state) => {
        state.isOpen = false;
        state.error = null;
      })
      .addCase(addDataCategory.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { openModal, closeModal } = addModalSlice.actions;
export default addModalSlice.reducer;
