import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const fetchUserDriveSchool = createAsyncThunk('fetchUserDriveSchool/fetchUserDriveSchool', async () => {
    try {
        const response = await axiosInstance.get('/user/getUserDriveSchool');
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const activeUserGuest = createAsyncThunk('activeUserGuest/activeGuest', async (data) => {
    try {
        const response = await axiosInstance.post('/payments/activeGuestFromUser', data)
        return response.data
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})


export const getMovmentDriveSchool = createAsyncThunk('getMovmentDriveSchool/getUser', async () => {
    try {
        const response = await axiosInstance.get('/payments/getMovmentDriveSchool')
        return response.data
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const getDebtDriveSchool = createAsyncThunk('getDebtDriveSchool', async () => {
    try {
        const response = await axiosInstance.get('/debt/getDebtDriveSchool')
        console.log(response.data)
        return response.data
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})


export const refusePaymentWithCash = createAsyncThunk(
    'deletePayment/deleteUser',
    async ({ catId, packageId, userId, paymentId }) => {
      try {
        const response = await axiosInstance.post(
          `/payments/refuseUserPayment/${catId}/${packageId}/${userId}/${paymentId}`
        );
        return response.data;
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          throw new Error(error.response.data.message);
        } else {
          throw new Error('An error occurred while processing the request.');
        }
      }
    }
  );

const userdriveschoolSlice = createSlice({
    name: 'userdriveschool',
    initialState: {
        data: [],   
        loading: false,
        error: null,
        dataMovment: [],
        loadingMovment: false,
        errorDataMovment: null,
        debt: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(refusePaymentWithCash.fulfilled, (state) => {
                state.loading = !state.loading;
            })
            .addCase(getDebtDriveSchool.fulfilled, (state, action) => {
                state.debt = action.payload;
            })
            .addCase(fetchUserDriveSchool.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserDriveSchool.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchUserDriveSchool.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(activeUserGuest.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(activeUserGuest.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(activeUserGuest.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getMovmentDriveSchool.pending, (state) => {
                state.loadingMovment = true;
                state.errorDataMovment = null;
            })
            .addCase(getMovmentDriveSchool.fulfilled, (state, action) => {
                state.loadingMovment = false;
                state.dataMovment = action.payload;
            })
            .addCase(getMovmentDriveSchool.rejected, (state, action) => {
                state.loadingMovment = false;
                state.errorDataMovment = action.error.message;
            });
            
    }
})

export const selectUserDriveSchool = (state) => state.userDriveSchoolSlice.data;
export const selectDataMovment = (state) => state.userDriveSchoolSlice.dataMovment;
export default userdriveschoolSlice.reducer;