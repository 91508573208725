import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstace from '../../api/axiosInstance';


export const fetchDataUsers = createAsyncThunk("api/fetchDataUsers", async () => {
  try {
    const response = await axiosInstace.get('/auth/findAll');
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});


export const activateGuestFromAdmin = createAsyncThunk('activateGuestFromUser', async (data) => {
  try {
    const response = await axiosInstace.post('/payments/activeGuestFromAdmin', data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
})


export const updateDataUsers = createAsyncThunk(
  "api/updateDataUsers",
  async ({ id, updatedData }) => {
    try {
      const response = await axiosInstace.post(`/auth/updateUser/${id}`, updatedData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteDataUsers = createAsyncThunk(
  "auth/deleteDataUsers",
  async (itemId, thunkAPI) => {
    try {
      await axiosInstace.delete(`/auth/deleteGuestUser/${itemId}`);
      return itemId;
    } catch (error) {
      throw new Error(error.response.data.message);

    }
  }
);

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (credentials) => {
    try {
      const response = await axiosInstace.post('/auth/login', credentials);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getActiveCategoryUserId = createAsyncThunk('getActiveCategory', async (userId) => {
  try {
    const response = await axiosInstace.get(`/payments/getUserActiveCategory/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
})
export const logoutUser = createAsyncThunk("auth/logout", async () => {
  localStorage.removeItem("userToken");
  localStorage.removeItem("isAuthenticated"); 
});

const initialState = {
  loading: false,
  user: null,
  error: null,
  success: false,
  data: [],
  activateGuestUser: [],
  laodingActivateGuestUser: false,
  errorActivateGuestUser: null,
  activeCategory: [],
  loadingActiveCategory: false,
  errorActiveCategory: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActiveCategoryUserId.pending, (state) => {
        state.loadingActiveCategory = true;
        state.errorActiveCategory = null;
      })
      .addCase(getActiveCategoryUserId.fulfilled, (state, action) => {
        state.activeCategory = action.payload;
        state.loadingActiveCategory = false;
      })
      .addCase(getActiveCategoryUserId.rejected, (state, action) => {
        state.loadingActiveCategory = false;
        state.errorActivateGuestUser = action.error.message;
      })
      .addCase(activateGuestFromAdmin.fulfilled, (state, action) => {
        state.laodingActivateGuestUser = false;
        state.activateGuestUser = action.payload
      })
      .addCase(activateGuestFromAdmin.pending, (state) => {
        state.laodingActivateGuestUser = true
        state.errorActivateGuestUser = null
      })
      .addCase(activateGuestFromAdmin.rejected, (state, action) => {
        state.laodingActivateGuestUser = false;
        state.errorActivateGuestUser = action.error.message;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
        state.success = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.error = action.error.message;
        state.success = false;
      })
      .addCase(logoutUser, (state) => {
        state.user = null;
        state.token = null;
        localStorage.removeItem("isAuthenticated");

      })
      .addCase(fetchDataUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDataUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDataUsers.fulfilled, (state, action) => {
        state.loading = false;
      })
      
      .addCase(updateDataUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
       .addCase(deleteDataUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDataUsers.fulfilled, (state, action) => {
        const deletedItemId = action.payload;
        state.data = state.data.filter((item) => item.id !== deletedItemId);
        state.loading = false;
      })
      .addCase(deleteDataUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectActiveCategoryUser = (state) => state.auth.activeCategory
export const selectAllUserData = (state) => state.auth.data;
export default authSlice.reducer;