import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPencilAlt } from "react-icons/fa";
import { FaRegCircleXmark } from "react-icons/fa6";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { fetchDataLiterature, updateDataLiterature } from "../../../store/slices/apiLiteratureSlice";
import { fetchDataCategories } from "../../../store/slices/apiCategorySlice";
import { openModal, closeModal } from "../../../store/slices/editModalLiteratureSlice";


const EditModalLiterature = ({
  id,
  title,
  submitButtonText,
  questionsName,
  CategoryName,
  premiumStatus

}) => {
  const dispatch = useDispatch();
  const dataLiterature = useSelector((state) => state.apiLiterature.data);
  const isOpen = useSelector((state) => state.editModalLiterature[id] === true);
  const [changeQuestionsName, setChangeQuestionsName] = useState("");
  const [LiteratureCategory, setLiteratureCategory] = useState("");
  const [desc, setDesc] = useState("");
  const isDisabled = true;
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [premiumLiterature, setPremiumLiterature] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    dispatch(fetchDataCategories());
    dispatch(fetchDataLiterature());
  }, []);

  const handlePremiumLiterature = (isChecked) => {
    setPremiumLiterature(isChecked);
  };
  const handleCloseModal = () => {
    dispatch(closeModal(id));
  };

  const handleOpenModal = () => {
    const itemToEdit = dataLiterature.find((item) => item.id === id);

    if (itemToEdit) {
      setChangeQuestionsName(itemToEdit.name);
      setLiteratureCategory(itemToEdit.categoryName);
      setSelectedPhoto(itemToEdit.icon)
      setPremiumLiterature(itemToEdit.isPremium === 1);
      setDesc(itemToEdit.description)
    }
    dispatch(openModal(id));
  };

  const handleOptionChange = (selectedOptions) => {
    setSelectedOption(selectedOptions);
  };

  const handleEditItem = () => {

    const formData = new FormData();
    formData.append("name", changeQuestionsName);
    formData.append("isPremium", premiumLiterature ? 1 : 0);
    formData.append("desc", desc)
    formData.append("photo", selectedPhoto)

    dispatch(updateDataLiterature({ id: id, updatedData: formData }));
    toast.success("Te dhenat jan ruajtur me sukses!");
    handleCloseModal();
  };


  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setSelectedPhoto(file);
  };

  return (
    <div>
      <button
        data-modal-target="popup-modal"
        data-modal-toggle="popup-modal"
        onClick={handleOpenModal}
      >
        <FaPencilAlt style={{ width: 20, height: 20 }} />
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
          <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
          <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "360px" }}
          >
            <button
              type="button"
              onClick={handleCloseModal}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
            >
              <FaRegCircleXmark style={{ width: 20, height: 20 }} />
            </button>

            <div className="p-6 text-center">
              <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                {title}
              </h3>
              <form className="space-y-6 text-start" onSubmit={handleEditItem}>
                <div>
                  <label
                    htmlFor="questionsName"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {questionsName}:
                  </label>
                  <input
                    type="text"
                    name="questionsName"
                    id="questionsName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder=""
                    value={changeQuestionsName}
                    onChange={(e) => setChangeQuestionsName(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="desc"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Pershkrimi:
                  </label>
                  <textarea
                    name="desc"
                    id="desc"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </div>

                <div>
                  <label
                    htmlFor="CategoryName"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {CategoryName}:
                  </label>
                  <input
                    type="text"
                    name="CategoryName"
                    id="CategoryName"
                    className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder=""
                    value={LiteratureCategory}
                    onChange={(e) => setLiteratureCategory(e.target.value)}
                    required
                    disabled={isDisabled}
                  />
                </div>


                {premiumStatus && (
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Premium:
                    </label>
                    <div className="flex">
                      <button
                        type="button"
                        className={`mr-2 text-sm rounded-lg px-3 py-1 ${premiumLiterature
                            ? "bg-green-500 text-white"
                            : "bg-gray-200 text-gray-700"
                          }`}
                        onClick={() => handlePremiumLiterature(true)}
                      >
                        Po
                      </button>
                      <button
                        type="button"
                        className={`text-sm rounded-lg px-3 py-1 ${!premiumLiterature
                            ? "bg-red-500 text-white"
                            : "bg-gray-200 text-gray-700"
                          }`}
                        onClick={() => handlePremiumLiterature(false)}
                      >
                        Jo
                      </button>
                    </div>
                  </div>
                )}

                <div>
                  <label
                    htmlFor="photo"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Photo:
                  </label>
                  <input
                    type="file"
                    name="photo"
                    id="photo"
                    accept=".png, .svg, .jpg"
                    onChange={handlePhotoChange}
                  />
                </div>
                <div className="flex justify-center">
                  <div className="flex items-center">
                    <button
                      data-modal-hide="popup-modal"
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      {submitButtonText}
                    </button>

                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={handleCloseModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Anulo
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditModalLiterature;