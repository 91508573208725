import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch,shallowEqual } from 'react-redux';
import { SearchBar, Pagination } from '../../components';
import { activeUserGuest, fetchUserDriveSchool } from '../../store/slices/userDriveSchoolSlice';
import { updateSearchQuery } from '../../store/slices/tableSlice';
import { calculateDaysForMonth, formatDateString, getCurrentDateTime, getFutureDateTime } from '../../util/time';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const TableUserDriveSchool = () => {
    const dispatch = useDispatch();
    const { currentPage, itemsPerPage, searchQuery } = useSelector((state) => state.table)
    const [searchInput, setSearchInput] = useState("");
    const dataUser = useSelector((state) => state.userDriveSchoolSlice.data);

    const filteredData = dataUser.filter((item) => {
        const name = item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase());
        const email = item.email && item.email.toLowerCase().includes(searchQuery.toLowerCase());
        const phoneNumber = item.phoneNumber && item.phoneNumber.toLowerCase(searchQuery.toLowerCase());
        const deviceId = item.deviceId && item.deviceId.toLowerCase().includes(searchQuery.toLowerCase());
        return (name || email || phoneNumber || deviceId) && item.deviceId !== null
    });
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = filteredData.slice(firstIndex, lastIndex);

    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        dispatch(updateSearchQuery(searchInput));
    };

    useEffect(() => {
        dispatch(fetchUserDriveSchool())
    }, [])
    
    return (
        <div className="p-3">
            <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
                <SearchBar
                    searchInput={searchInput}
                    handleSearchInputChange={handleSearchInputChange}
                    handleSearchSubmit={handleSearchSubmit}
                />
                <div></div>
            </div>
          
            <div
                className="relative overflow-x-auto shadow-md sm:rounded-lg"
                style={{ borderRadius: 1 }}
            >
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Emri dhe Mbiemri
                            </th>

                            <th scope="col" className="px-6 py-3">
                                Emaili
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Numri Telefonit
                            </th>

                            <th scope="col" className="px-6 py-3">
                                Qyteti
                            </th>
                          
                     
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item) => {
                            return (
                                <tr
                                    key={item.userId + item.id}
                                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                >
                                    <td className="px-6 py-4">{item.name}</td>
                                    <td className="px-6 py-4">{item.email}</td>
                                    <td className="px-6 py-4">{item.phoneNumber}</td>
                                    <td className='px-6 py-4'>{item.city}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <Pagination
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    filteredData={filteredData}
                />
            </div>
        </div>
    )
}

export default TableUserDriveSchool