import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "../../api/axiosInstance";


export const fetchDebtAllUser = createAsyncThunk('fetchDebt', async () => {
    try {
        const response = await axiosInstace.get("/debt/getDriveSchool");
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const getAllPaymet = createAsyncThunk('getAllPayment', async (req, res) => {
    try {
        const response = await axiosInstace.get('/payment/getAllPayment');
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message)
    }
})

export const payDebtDriveSchool = createAsyncThunk('peyDebtDriveSchool', async (data) => {
    try {
        const response = await axiosInstace.post("/debt/payDebtDriveSchool", data);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})


export const getAllDebt = createAsyncThunk('getAllDebt', async () => {
    try {
        const response = await axiosInstace.get('/payment/getAllDebt');
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message)
    }
})
 
export const resetPay = createAsyncThunk('resetPay', async (id) => {
    try {
        const response = await axiosInstace.post(`/payments/resetPayment/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message)
    }
})
const debtSlice = createSlice({
    name: 'debt',
    initialState: {
        data: [],
        loading: false,
        error: null,
        payDebtStatus: false,
        payDebtLoading: false,
        payDebtError: null,
        paymentData: [],
        loadindPaymentData: false,
        errorPaymentData: null,
        allDebt: [],
        loadingAllDebt: false,
        errorAllDebt: null,
        resetPay: [],
        errorResetPay: null,
        loadingResetPay: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(resetPay.fulfilled, (state, action) => {
                state.loadingResetPay = false;
                state.resetPay = action.payload;
            })
            .addCase(resetPay.rejected, (state, action) => {
                state.errorResetPay = action.error.message;
                state.loadingResetPay = false
            })
            .addCase(resetPay.pending, (state) => {
                state.loadingResetPay = true;
                state.errorResetPay = null;
            })
            .addCase(getAllDebt.pending, (state) => {
                state.loadingAllDebt = true;
                state.errorAllDebt = null
            })
            .addCase(getAllDebt.fulfilled, (state, action) => {
                state.loadingAllDebt = false;
                state.allDebt = action.payload;
            })
            .addCase(getAllDebt.rejected, (state, action) => {
                state.loadingAllDebt = false;
                state.errorAllDebt = action.error.message;
            })
            .addCase(getAllPaymet.pending, (state) => {
                state.loadindPaymentData = true;
                state.errorPaymentData = null
            })
            .addCase(getAllPaymet.fulfilled, (state, action) => {
                state.loadindPaymentData = false;
                state.paymentData = action.payload;
            })
            .addCase(getAllPaymet.rejected, (state, action) => {
                state.loadindPaymentData = false;
                state.errorPaymentData = action.error.message;
            })
            .addCase(fetchDebtAllUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDebtAllUser.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchDebtAllUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(payDebtDriveSchool.pending, (state) => {
                state.payDebtLoading = true;
                state.payDebtError = null;
            })
            .addCase(payDebtDriveSchool.fulfilled, (state, action) => {
                state.payDebtStatus = !state.payDebtStatus;

            })
            .addCase(payDebtDriveSchool.rejected, (state, action) => {
                state.payDebtLoading = false;
                state.payDebtError = action.error.message;
            })
    }
})

export const selectAllDebt = (state) => state.debt.data
export const selectAllPayment = (state) => state.debt.paymentData
export default debtSlice.reducer;