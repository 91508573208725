import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchDataDrivingSchools, selectDriveSchool } from "../../store/slices/apiDrivingSchoolsSlice";
import { fetchDataUsers } from "../../store/slices/authSlice";
import { fetchAllActiveCategory, fetchAllPayment } from "../../store/slices/paymentSlice";
import { getAllDebt } from "../../store/slices/debtSlice";
import { fetchDataExams } from "../../store/slices/apiExamsSlice";
import { fetchDataQuestionCategory, fetchQuestionWithAnswerCategory } from "../../store/slices/apiQuestionCategorySlice";
import { fetchDataQuestions } from "../../store/slices/apiQuestionSlice";
import { fetchDataLiterature, fetchDataTextLiterature } from "../../store/slices/apiLiteratureSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const dataDriveSchool = useSelector(selectDriveSchool);
  const user = useSelector((state) => state.auth.data)
  const payment = useSelector((state) => state.payment.data)
  const activePayment = payment?.filter((item) => item.isActive === 1)
  const activeCat = useSelector((state) => state.payment.activeCat);
  const filterActiveCat = activeCat?.filter((item) => item.driveSchool_id === null)
  const activePaymentCatA = activePayment?.filter((item) => item.category_id === 10)
  const activePaymentCatB = activePayment?.filter((item) => item.category_id === 11)
  const activePaymentCatC1 = activePayment?.filter((item) => item.category_id === 12)
  const activePaymentCatC = activePayment?.filter((item) => item.category_id === 13)
  const activePaymentCatD = activePayment?.filter((item) => item.category_id === 18)
  const dataExams = useSelector((state) => state.apiExams.data);
  const dataQuestionCategory = useSelector((state) => state.apiQuestionCategory.data);
  const dataQuestions = useSelector((state) => state.apiQuestion.data);
  const dataQuestion = useSelector((state) => state.apiQuestionCategory.dataQuestion);
  const dataLiterature = useSelector((state) => state.apiLiterature.data);
  const dataTextLiterature = useSelector((state) => state.apiLiterature.dataTextLirature);

  useEffect(() => {
    dispatch(fetchDataDrivingSchools())
    dispatch(fetchDataUsers())
    dispatch(fetchAllPayment())
    dispatch(getAllDebt())
    dispatch(fetchAllActiveCategory())
    dispatch(fetchDataExams())
    dispatch(fetchDataQuestionCategory())
    dispatch(fetchDataQuestions())
    dispatch(fetchDataLiterature())
    dispatch(fetchQuestionWithAnswerCategory())
    dispatch(fetchDataTextLiterature())
  }, [])
  return (
    <div className="mt-24">
      <div className="flex flex-wrap  justify-center ">
       
      <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Totali i autoshkollave</span>
              <p className=''>
                {dataDriveSchool.length}
              </p>
            </p>
          </div>
        </div>


        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Totali i kandidatve</span>
              <p className=''>
                {user.length}
              </p>
            </p>
          </div>
        </div>

        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Totali i Pagesave</span>
              <p className=''>
                {activePayment.length}
              </p>
            </p>
          </div>
        </div>

        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Totali i Aktivizimit te paketave</span>
              <p className=''>
                {activeCat.length}
              </p>
            </p>
          </div>
        </div>

        
        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Totali i Aktivizimit Direkt nga banka</span>
              <p className=''>
                {filterActiveCat.length}
              </p>
            </p>
          </div>
        </div>

        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Totali i Aktivizimit i Kategoris A</span>
              <p className=''>
                {activePaymentCatA.length}
              </p>
            </p>
          </div>
        </div>

        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Totali i Aktivizimit i Kategoris B</span>
              <p className=''>
                {activePaymentCatB.length}
              </p>
            </p>
          </div>
        </div>


        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Totali i Aktivizimit i Kategoris C1</span>
              <p className=''>
                {activePaymentCatC1.length}
              </p>
            </p>
          </div>
        </div>

        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Totali i Aktivizimit i Kategoris C</span>
              <p className=''>
                {activePaymentCatC.length}
              </p>
            </p>
          </div>
        </div>

        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Totali i Aktivizimit i Kategoris D</span>
              <p className=''>
                {activePaymentCatD.length}
              </p>
            </p>
          </div>
        </div>

        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Totali i Testeve</span>
              <p className=''>
                {dataExams.length}
              </p>
            </p>
          </div>
        </div>

    

        
        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Totali i Pyetjeve te testeve</span>
              <p className=''>
                {dataQuestions.length}
              </p>
            </p>
          </div>
        </div>


        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Lista e pyetjeve me kategori</span>
              <p className=''>
                {dataQuestion.length}
              </p>
            </p>
          </div>
        </div>



        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Totali i Literaturave</span>
              <p className=''>
                {dataLiterature.length}
              </p>
            </p>
          </div>
        </div>

        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <p className="mt-3">
              <span className="font-bold text-gray-400">Totali i materialit te literaturave</span>
              <p className=''>
                {dataTextLiterature.length}
              </p>
            </p>
          </div>
        </div>




        
      </div>
    </div>
  );
};

export default Dashboard;
