import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeMenu: true,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setActiveMenu: (state, action) => {
      state.activeMenu = action.payload;
    },
  },
});

export const { setActiveMenu } = appSlice.actions;

export default appSlice.reducer;
