import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../store/slices/infoModalSlice";
import { FaCircleInfo } from "react-icons/fa6";

const InfoModal = ({
  onClick,
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.infoModal.isOpen);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <div>
      <button
        data-modal-target="popup-modal"
        data-modal-toggle="popup-modal"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <FaCircleInfo style={{ width: 20, height: 20 }} />
      </button>

      {isOpen && (
        <form>
                
                <div className="flex justify-center">
                  <div className="flex items-center">
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={handleCloseModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Mbyll
                    </button>
                  </div>
                </div>
              </form>
            
      )}
    </div>
  );
};

export default InfoModal;
