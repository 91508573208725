import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../store/slices/addModalSlice";
import { FaRegCircleXmark } from "react-icons/fa6";
import { createDataQuestions, fetchDataQuestions } from "../../store/slices/apiQuestionSlice";
import Select from "react-select";
import { toast } from "react-toastify";
import { fetchDataExams } from "../../store/slices/apiExamsSlice";
import { fetchDataQuestionCategory } from "../../store/slices/apiQuestionCategorySlice";

const AddModalQuestions = ({
  title,
  submitButtonText,
  exams,
  questionsAnswer1,
  questionsAnswer2,
  score,
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.addModal.isOpen);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedQuestionCategories, setSelectedQuestionCategories] = useState([]);
  const categoryOptions = useSelector((state) => state.apiExams.data);
  const questionsCategoryOptions = useSelector(
    (state) => state.apiQuestionCategory.data
  );
  const [questionText, setQuestionText] = useState("");
  const [questionScore, setQuestionScore] = useState("");
  const [questionAnswers, setQuestionAnswers] = useState([
    { text: "", isCorrect: false },
    { text: "", isCorrect: false },
    { text: "", isCorrect: false },
    
  ]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  useEffect(() => {
    dispatch(fetchDataExams());
    dispatch(fetchDataQuestionCategory());
  }, [dispatch]);

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setSelectedPhoto(file);
  };

  const handleOpenModal = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleOptionChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
  };

  const handleOptionChange2 = (selectedOptions) => {
    setSelectedQuestionCategories(selectedOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedCategoryIds = selectedCategories.map(
      (option) => option.value
    );
    const selectedQuestionCategoryIds = selectedQuestionCategories.map(
      (option) => option.value
    );

    const answersData = questionAnswers.map((answer) => ({
      answer_text: answer.text,
      is_correct: answer.isCorrect,
    }));

    const formData = new FormData();
    formData.append("question_text", questionText);
    formData.append("test_id", JSON.stringify(selectedCategoryIds));
    formData.append(
      "categoryQuestionIds",
      JSON.stringify(selectedQuestionCategoryIds)
    );
    formData.append("answer_data", JSON.stringify(answersData));
    formData.append("photo", selectedPhoto);
    formData.append("score", questionScore);


    try {
      dispatch(createDataQuestions(formData));
      setQuestionText("");
      setSelectedPhoto(null);
      setQuestionAnswers([
        { text: "", isCorrect: false },
        { text: "", isCorrect: false },
        { text: "", isCorrect: false },
      ]);
      setSelectedCategories([]);
      setSelectedQuestionCategories([]);
      setQuestionScore("");
      toast.success("Pyetja u shtua me sukses!");
    } catch (error) {
      console.log("Error submitting data:", error);
    }
  };

  return (
    <div>
      <button
        data-modal-target="popup-modal"
        data-modal-toggle="popup-modal"
        onClick={handleOpenModal}
        className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-2.5 py-2   hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
      >
        Shto Pyetje
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
          <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
          <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "420px" }}
          >
            <button
              type="button"
              onClick={handleCloseModal}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
            >
              <FaRegCircleXmark style={{ width: 20, height: 20 }} />
            </button>

            <div className="p-6 text-center">
              <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                {title}
              </h3>
              <form className="space-y-6 text-start" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="questionText"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Shkruaj pyetjen:
                  </label>
                  <input
                    type="text"
                    name="questionText"
                    id="questionText"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Shkruaj pyetjen"
                    value={questionText}
                    onChange={(e) => setQuestionText(e.target.value)}
                    required
                  />
                </div>

                {exams && (
                  <div>
                    <label
                      htmlFor="selectedCategories"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {exams}:
                    </label>
                    <Select
                      isMulti
                      name="selectedCategories"
                      id="selectedCategories"
                      value={selectedCategories}
                      onChange={handleOptionChange}
                      options={categoryOptions.map((option) => ({
                        value: option.id,
                        label: `${option.name} ( ${option.categoryName})`,
                      }))}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    />
                  </div>
                )}

         

                {questionsAnswer1 && (
                  <div>
                    <div className="flex justify-between ">
                      <label
                        htmlFor="photoSubliterature"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white "
                      >
                        {questionsAnswer1}:
                      </label>
                      <label
                        htmlFor="photoSubliterature"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white "
                      >
                        {questionsAnswer2}:
                      </label>
                    </div>
                    {questionAnswers.map((answer, index) => (
                      <div className="flex" key={index}>
                        <input
                          type="text"
                          id={`questionAnswers${index}`}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white mb-2"
                          placeholder={`Shkruaj pergjigjen ${index + 1}`}
                          value={answer.text}
                          onChange={(e) => {
                            const newAnswers = [...questionAnswers];
                            newAnswers[index].text = e.target.value;
                            setQuestionAnswers(newAnswers);
                          }}
                          required
                        />
                        <div className="flex items-center ml-4">
                          <input
                            type="checkbox"
                            className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            checked={answer.isCorrect}
                            onChange={() => {
                              const newAnswers = [...questionAnswers];
                              newAnswers[index].isCorrect =
                                !newAnswers[index].isCorrect;
                              setQuestionAnswers(newAnswers);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {score && (
                  <div>
                    <label
                      htmlFor="questionScore"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {score}:
                    </label>
                    <input
                      type="text"
                      name="questionScore"
                      id="questionScore"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Shkruaj piket"
                      value={questionScore}
                      onChange={(e) => setQuestionScore(e.target.value)}
                      required
                    />
                  </div>
                )}
                <div>
                  <label
                    htmlFor="photo"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Foto:
                  </label>
                  <input
                    type="file"
                    name="photo"
                    id="photo"
                    accept=".png, .svg, .jpg"
                    onChange={handlePhotoChange}
                    required
                  />
                </div>

                <div className="flex justify-center">
                  <div className="flex items-center">
                    <button
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      {submitButtonText}
                    </button>

                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={handleCloseModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Anulo
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddModalQuestions;

