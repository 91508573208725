import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal,closeModal } from "../../store/slices/editModalSlice";
import { closeModalDelete, openModalDelete } from "../../store/slices/deleteModalDrivingSchoolsSlice";
import InfoModal from "../Modals/InfoModal";
import EditModalExams from "../Modals/EditModal/EditModalExams";
import DeleteModalExams from "../Modals/DeleteModal/DeleteModalExams";
import EditModalDrivingSchools from "../Modals/EditModal/EditModalDrivingSchools";
import DeleteModalDrivingSchools from "../Modals/DeleteModal/DeleteModalDrivingSchools";

const TableItemsDriving = ({ dataDrivingSchools }) => {
  const dispatch = useDispatch();
  const editModalState = useSelector((state) => state.editModalDrivingSchools); 


  const handleEditItem = (id) => {
    dispatch(openModal(id));
  };

  const handleDeleteItem = (id) => {
    dispatch(openModalDelete(id));
  };

  function getYesNoTextFromBoolean(value) {
    return value ? "Po" : "Jo";
  }

  function getStatusTextFromBoolean(value) {
    return value ? "Aktiv" : "Pasiv";
  }
  return (
    <>
      {dataDrivingSchools.map((item) => (
        <tr
          key={item.id}
          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {item.name}
          </td>
          <td className="px-6 py-4">{item.city}</td>
          <td className="px-6 py-4">
            <td
              className={`px-2 py-1 p-1 rounded-full w-5 h-5 ${item.status === 1 ? "bg-green-500" : "bg-red-500"
                }`}
            >
              <label className="text-white">{getStatusTextFromBoolean(item.status)}</label>
            </td>
          </td>
          <td className="px-6 py-4">{getYesNoTextFromBoolean(item.cash)}</td>
          <td className="px-6 py-4">{item.userEmail}</td>
          <td className="px-6 py-4">
            {new Date(item.created_at).toLocaleString('en-US', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour12: false
            })}
          </td>
          
          <td className="px-6 py-4 w-40">
            <button className="text-blue-500 mr-2">
              <InfoModal
                title="Info rreth testit"
                name="Emri i testit"
                categoryInInfo="Kategoria e testit"
              />
              </button>
              <>
                <button className="text-sucess mx-1" onClick={() => handleEditItem(item.id)}>
                  <EditModalDrivingSchools
                    title="Edito nje autoshkollë"
                    id={item.id}
                    drivingSchoolName="Edito një autoshkollë"
                    cityName="Qyteti"
                    schoolStatus="Statusi"
                    schoolCash="Me para ne dore"
                    submitButtonText="Edito "
                    isOpen={editModalState[item.id] === true}
                  />
                </button>
                <button className="text-danger mx-1" onClick={() => handleDeleteItem(item.id)}>
                  <DeleteModalDrivingSchools
                    id={item.id}
                  />
                </button>
              </>
          </td>
        </tr>
      ))}
    </>
  );
};

export default TableItemsDriving;
