import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../store/slices/infoModalSlice";
import { FaCircleInfo,FaRegCircleXmark } from "react-icons/fa6";
import { options } from "../../data/dataTable";
import Select from "react-select";

const InfoModal = ({
  title,
  onSubmit,
  name,
  categoryInInfo,
  inputLabel3,
  inputLabel4,
  onClick,
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.infoModal.isOpen);

  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [inputValue3, setInputValue3] = useState("");
  const [inputValue4, setInputValue4] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOpenModal = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleOptionChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(inputValue1, inputValue2, inputValue3, inputValue4);
    setInputValue1("");
    setInputValue2("");
    setInputValue3("");
    setInputValue4("");
    handleCloseModal();
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
  };

  return (
    <div>
      <button
        data-modal-target="popup-modal"
        data-modal-toggle="popup-modal"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <FaCircleInfo style={{ width: 20, height: 20 }} />
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
          <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
          <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "360px" }}
          >
            <button
              type="button"
              onClick={handleCloseModal}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
            >
              <FaRegCircleXmark style={{ width: 20, height: 20 }} />
            </button>

            <div className="p-6 text-center">
              <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                {title}
              </h3>
              <form className="space-y-6 text-start" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="input1"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {name}:
                  </label>
                  <input
                    type="text"
                    name="input1"
                    id="input1"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder=""
                    value=""
                    onChange={(e) => setInputValue1(e.target.value)}
                    disabled
                  />
                </div>

                {categoryInInfo && (
                  <div>
                    <label
                      htmlFor="input2"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {categoryInInfo}:
                    </label>
                    <input
                      type="text"
                      name="input2"
                      id="input2"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder=""
                      value={inputValue2}
                      onChange={(e) => setInputValue2(e.target.value)}
                      disabled
                    />
                  </div>
                )}

                {inputLabel3 && (
                  <div>
                    <label
                      htmlFor="input3"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {inputLabel3}:
                      <div className="d-flex justify-content-start ">
                        Kategoritë e zgjedhura :{" "}
                        {selectedOptions
                          .map((option) => option.value)
                          .join(", ")}
                      </div>
                    </label>
                    <Select
                      isMulti
                      name="input3"
                      id="input3"
                      value={selectedOptions}
                      onChange={handleOptionChange}
                      options={options}
                      styles={customStyles}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    />
                  </div>
                )}

                {inputLabel4 && (
                  <div>
                    <label
                      htmlFor="input4"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {inputLabel4}:
                    </label>
                    <input
                      type="text"
                      name="input4"
                      id="input4"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder=""
                      value={inputValue4}
                      onChange={(e) => setInputValue4(e.target.value)}
                      disabled
                    />
                  </div>
                )}

                <div className="flex justify-center">
                  <div className="flex items-center">
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={handleCloseModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Mbyll
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoModal;
