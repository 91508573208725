import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: { role: 'user' },
  reducers: {
    setUserRole: (state, action) => {
      state.role = action.payload;
    },
    setAdminRole: (state) => {
      state.role = 'admin';
    },
  },
});

export const { setUserRole, setAdminRole } = userSlice.actions;
export default userSlice.reducer;

