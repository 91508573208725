import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from '../../components';
import { getCurrentDateTime, getFutureDateTime, formatDateString, isUserActive } from '../../util/time';
import { refusePaymentWithCash, getDebtDriveSchool, activeUserGuest, getMovmentDriveSchool, selectDataMovment } from '../../store/slices/userDriveSchoolSlice';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const MovmentDriveSchool = () => {
    const dispatch = useDispatch();
    const dataMovment = useSelector(selectDataMovment).filter(item => item.package !== null) || [];
    const loading = useSelector((state) => state.userDriveSchoolSlice.loading);
    const { currentPage, itemsPerPage, searchQuery } = useSelector((state) => state.table);
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = dataMovment.slice(firstIndex, lastIndex);
    const [pay, setPay] = useState(false)
    const debt = useSelector((state) => state.userDriveSchoolSlice.debt)
    // const filteredData = dataMovment.filter((item) => {
    //     const name = item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase());
    //     const email = item.email && item.email.toLowerCase().includes(searchQuery.toLowerCase());
    //     const phoneNumber = item.phoneNumber && item.phoneNumber.toLowerCase(searchQuery.toLowerCase());
    //     const deviceId = item.deviceId && item.deviceId.toLowerCase().includes(searchQuery.toLowerCase());
    //     return name || email || phoneNumber || deviceId
    // });


    const handelActiveUser = (id, userId, catId, driveSchoolId, month) => {

        const data = {
            id,
            userId,
            catId,
            activeStart: getCurrentDateTime(),
            activeEnd: getFutureDateTime(month),
            driveSchoolId
        }
        dispatch(activeUserGuest(data))
        toast.success("Kandidati u aktivizua me sukses")
    }
    const handleRefuse = (catId, packageId, userId, paymentId) => {
        dispatch(refusePaymentWithCash({ catId, packageId, userId, paymentId }))
        toast.error("Kandiadti u refuzua")
    }

    const totalCalculation = dataMovment?.reduce((total, payment) => {
        if (payment.isActive) {
            return total + payment.amount
        }
        return total;
    }, 0);

    const profitDriveSchool = dataMovment?.reduce((total, payment) => {
        if (payment.isActive) {
            return total + payment.schoolPrice
        }
        return total;
    }, 0)
    const totalAdmin = dataMovment.reduce((total, payment) => {
        if (payment.isActive) {
            if (payment.cash === 1) {
                return total + payment.adminPrice
            } else {
                return total - payment.schoolPrice
            }
        }
        return total
    }, 0);

    const calculateDebt = debt?.reduce((total, debt) => {
        return total + debt.amount
    }, 0)

    function makePositive(number) {
        if (number < 0) {
            return -number;
        } else {
            return number;
        }
    }

    useEffect(() => {
        dispatch(getMovmentDriveSchool())
        dispatch(getDebtDriveSchool())
    }, [loading])

    return (
        <div>
            <div className='flex flex-col items-center justify-around  md:flex-row mt-[100px] mb-10'>
                <div className='flex flex-col md:flex-row w-[90%]  md:w-[70%] md:space-x-4'>
                    <div className='py-5 mt-5 px-8 rounded-[15px] bg-[#facc15]'>
                        <p className='text-white'>Qarkullimi total <span className='text-black font-bold'>({totalCalculation} €)</span></p>
                    </div>
                    <div className='py-5 mt-5 px-8 rounded-[15px] bg-green-500'>
                        <p className='text-white'>Fitimi i autoshkolles <span className='text-black font-bold'>({profitDriveSchool} €)</span></p>
                    </div>
                    <div className={`py-5 mt-5 px-8 rounded-[15px] ${(totalAdmin - calculateDebt) > 0 ? "bg-red-500" : "bg-green-500"}`}>
                        <p className='text-white'>Mosbarazimi<span className='text-black font-bold'> ({(totalAdmin - calculateDebt) > 0 ? "" : "+"} {makePositive(totalAdmin - calculateDebt)} €)</span></p>
                    </div>
                </div>
                <button className='bg-blue-500 mt-5 w-[90%] md:w-[300px] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-[15px]' onClick={() => { setPay(!pay) }}>Shiko pagesat</button>
            </div>

            <div className="m-2 md:m-0 p-2 md:p-6 bg-white rounded-3xl">

                <p className="text-xl mb-4 text-left">Qarkullimi i Autoshkolles</p>

                <div className="p-3">

                    <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
                        {/* <SearchBar
            searchInput={searchInput}
            handleSearchInputChange={handleSearchInputChange}
            handleSearchSubmit={handleSearchSubmit}
        /> */}
                        <div></div>
                    </div>

                    <div
                        className="relative overflow-x-auto shadow-md sm:rounded-lg"
                        style={{ borderRadius: 1 }}
                    >
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Kandidati
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Kategoria
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Pako
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Menyra e pageses
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Per pages
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Skadon Me
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Statusi
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Bilanci
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Aksion
                                    </th>


                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((item) => {
                                    console.log(currentItems)
                                    return (
                                        <tr
                                            key={item}
                                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                        >
                                            <td className="px-6 py-4">{item.name}</td>
                                            <td className="px-6 py-4">{item.catName}</td>
                                            <td className="px-6 py-4">{item.package}</td>
                                            <td className="px-6 py-4">{item.cash === 1 ? "Para ne dore" : "Permes Bankes"}</td>
                                            <td className='px-6 py-4 text-[#FFC500] font-bold'>{item.amount} €</td>
                                            <td className='px-6 py-4 font-bold'>{item.activeEnd ? formatDateString(item.activeEnd) : ""}</td>
                                            <td className='px-6 py-4'>{isUserActive(item.activeStart, item.activeEnd) ? <span className="text-green-500 font-bold">Aktiv</span> : item.activeStart ?
                                                <span className="text-red-500 font-bold">Skaduar</span> : <span className="text-[#FFC500] font-bold">Ne Pritje</span>}</td>
                                            <td className='px-6 py-4 font-bold'>
                                                <span className='text-red-500'>
                                                    {item.cash === 1 ? `- ${item.adminPrice}` : (<span className='text-green-500'>+ {item.schoolPrice} €</span>)}
                                                </span>
                                            </td>
                                            <td className='px-6 py-4 flex'>

                                                {item.user_id ? item.isActive ? (
                                                    <button className='bg-green-500  text-white font-bold py-2 px-4 rounded'>
                                                        Aktivizuar
                                                    </button>
                                                ) : (
                                                    <>
                                                        <button
                                                            onClick={() => handelActiveUser(item.payment_id, item.user_id, item.category_id, item.driveSchool_id, item.month)}
                                                            className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-[20px]'
                                                        >
                                                            Aktivizo
                                                        </button>
                                                        <button
                                                            onClick={() => handleRefuse(item.category_id, item.packageId, item.user_id, item.payment_id)}
                                                            className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded rounded-[20px]'
                                                        >
                                                            Refuzo
                                                        </button>
                                                    </>
                                                ) : (
                                                    item.isActive ? (
                                                        <button className='bg-green-500  text-white font-bold py-2 px-4 rounded'>
                                                            Aktivizuar
                                                        </button>
                                                    ) :
                                                        <button
                                                            onClick={() => handleRefuse(item.category_id, item.packageId, item.user_id, item.payment_id)}
                                                            className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded rounded-[20px]'
                                                        >
                                                            Refuzo
                                                        </button>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>


                        <Pagination
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            filteredData={dataMovment}
                        />
                    </div>
                    {
                        pay && (<div class="modal  fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50">

                            <div class="modal-content mx-auto my-10 p-6 bg-white w-1/2 rounded">


                                <div>
                                    <div className='flex justify-between'>
                                        <p>Pagesat</p>
                                        <span onClick={() => setPay(!pay)} class="close cursor-pointer">&times;</span>
                                    </div>

                                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                <tr>
                                                    <th scope="col" class="px-6 py-3">
                                                        Data e pageses
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Shuma e pageses
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    debt.map(item => {
                                                        return (
                                                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                    {formatDateString(item.create_at)}
                                                                </th>
                                                                <td class="px-6 py-4">
                                                                    {item.amount} €
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }



                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>
                        </div>)
                    }

                </div>
            </div>
        </div>
    )
}

export default MovmentDriveSchool