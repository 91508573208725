import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateData, updateSearchQuery } from "../../store/slices/tableSlice";
import { SearchBar, TableItems, Pagination } from '../../components'
import { closeModal } from "../../store/slices/deleteModalSlice";
import TableItemsExams from "../TableItemsExams/TableItemsExams";
import { fetchDataExams, createDataExams } from "../../store/slices/apiExamsSlice";
import AddModalExams from "../Modals/AddModalExams";

const TableExams = () => {
  const dispatch = useDispatch();
  const { currentPage, itemsPerPage, searchQuery } = useSelector(
    (state) => state.table
  );
  const dataExams = useSelector((state) => state.apiExams.data);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    dispatch(fetchDataExams());
  }, []);

  const filteredData = dataExams.filter((item) => {
    const name = item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())
    const categoryName = item.categoryName && item.categoryName.toLowerCase().includes(searchQuery.toLowerCase())
    return name || categoryName
  });

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = filteredData.slice(firstIndex, lastIndex);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleAddExams = (data) => {
    dispatch(createDataExams(data));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };

  return (
    <div className="p-3">
      <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
        <SearchBar
          searchInput={searchInput}
          handleSearchInputChange={handleSearchInputChange}
          handleSearchSubmit={handleSearchSubmit}
        />
        <div>
          <button className="text-blue-500 mx-2" alt="you can edit here">
            <AddModalExams
              title="Shto një test"
              submitButtonText="Shto testin"
              onCancel={handleCloseModal}
              onSubmit={handleAddExams}
              inputLabel1="Emri i testit"
              inputLabel2="Zgjedh kategorinë"
              premiumStatus="A eshte premium"

            />
          </button>
        </div>
      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Lista e Testeve
              </th>
              <th scope="col" className="px-6 py-3">
                Kategoria
              </th>
              <th scope="col" className="px-6 py-3">
                Premium
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <TableItemsExams
            dataExams={currentItems}
            showCity={false}
            showCategory={false}
          />
        </table>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          filteredData={filteredData}
        />
      </div>
    </div>
  );
};

export default TableExams;