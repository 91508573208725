import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { setActiveMenu } from "../../store/slices/appSlice";
import { links } from "../../data/dataTable";
import jwtDecode from "jwt-decode";

const Sidebar = () => {
  const activeMenu = useSelector((state) => state.app.activeMenu);
  const userRole = localStorage.getItem('userToken') && jwtDecode(localStorage.getItem('userToken')).role;
  const dispatch = useDispatch();

  const handleCloseSideBar = () => {
    dispatch(setActiveMenu(false));
  };

  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize <= 900 && activeMenu) {
        dispatch(setActiveMenu(false));
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeMenu, dispatch]);

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white bg-theme-color text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 hover:bg-light-gray m-2";
  const dropdownLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 hover:bg-light-gray m-2 cursor-pointer";

  const handleNavLinkClick = () => {
    const screenSize = window.innerWidth;
    if (screenSize <= 900 && activeMenu) {
      dispatch(setActiveMenu(false));
    }
  };

  const checkUserLinkAuthentication = (dataUser) => {
    return dataUser.includes(userRole)
  }
  return (
    <div
      className={`ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 ${activeMenu ? "block" : "hidden"
        }`}
    >
      <div className="flex justify-between items-center">
        <Link
          onClick={handleCloseSideBar}
          className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
        >
          <img
            src="/assets/images/logo.svg"
            style={{ width: 28, height: 28 }}
            alt="Logo"
          />{" "}
          <span>Autoshkollaime</span>
        </Link>
        <button
          type="button"
          onClick={handleCloseSideBar}
          className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
        >
          <MdOutlineCancel />
        </button>
      </div>
      <div className="mt-10">
        {links.map((item) => (
          <div key={item.title}>
            {userRole === "admin" ? (
              <p className="text-gray-400 m-3 mt-4 uppercase">{item.title}</p>
            ) : null}
            {item.links.map(link => {
              const isAuthorized = checkUserLinkAuthentication(link.isAdmin);
              if (isAuthorized) {
                if (link.isDropdown) {
                  return (
                    <div key={link.name}>
                      <div className="flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 m-2 cursor-pointer">
                        {link.icon}
                        <span className="capitalize text-black-500">{link.title}</span>
                      </div>
                      <div className="pl-6">
                        {link.links.map((subLink) => (
                          <NavLink
                            to={`/${subLink.name}`}
                            key={subLink.name}
                            onClick={handleNavLinkClick}
                            className={({ isActive }) =>
                              isActive ? activeLink : normalLink
                            }
                          >
                            {subLink.icon}
                            <span className="capitalize text-black-500">{subLink.title}</span>
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <NavLink
                      to={`/${link.name}`}
                      key={link.name}
                      onClick={handleNavLinkClick}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      {link.icon}
                      <span className="capitalize text-black-500">{link.title}</span>

                    </NavLink>
                  );
                }
              }
              return null;
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;




// link.isDropdown ? (
//   <div key={link.name}>
//     {userRole === "admin" ||
//       (userRole === "user" && link.name === "drivingschools") ? (
//       <div className="flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 m-2 cursor-pointer">
//         {link.icon}
//         <span className="capitalize text-black-500">{link.title}</span>
//       </div>
//     ) : null}
//     {userRole === "admin" ||
//       (userRole === "user" && link.name === "drivingschools") ? (
//       <div className="pl-6">
//         {link.links.map((subLink) => (
//           <NavLink
//             to={`/${subLink.name}`}
//             key={subLink.name}
//             onClick={handleNavLinkClick}
//             className={({ isActive }) =>
//               isActive ? activeLink : normalLink
//             }
//           >
//             {subLink.icon}
//             <span className="capitalize text-black-500">{subLink.title}</span>
//           </NavLink>
//         ))}
//       </div>
//     ) : null}
//   </div>
// ) : (
//   (userRole === "admin" ||
//     (userRole === "user" &&
//       (link.name === "drivingschools" ||
//         link.name === "users" ||
//         link.name === "dashboard"))) && (
//     <NavLink
//       to={`/${link.name}`}
//       key={link.name}
//       onClick={handleNavLinkClick}
//       className={({ isActive }) =>
//         isActive ? activeLink : normalLink
//       }
//     >
//       {link.icon}
//       <span className="capitalize text-black-500">{link.title}</span>
//     </NavLink>
//   )
// )