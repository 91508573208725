import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal} from "../../store/slices/addModalQuestionCategorySlice";
import { FaRegCircleXmark } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { fetchDataLiterature } from "../../store/slices/apiLiteratureSlice";
import { createDataQuestionCategory, fetchDataQuestionCategory } from "../../store/slices/apiQuestionCategorySlice";
import { useNavigate } from "react-router-dom";

const AddModalQuestionCategory = ({
  title,
  submitButtonText,
  nameQuestionCategory,
  categoryQuestionCategory,
  photoQuestionCategory,
  premiumStatus
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.addModalQuestionCategory.isOpen);

  const [questionCategoryName, setQuestionCategoryName] = useState("");
  const [photoSelect, setPhotoSelect] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const literatureOptions = useSelector((state) => state.apiCategory.data);
  const [premium, setPremium] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchDataLiterature()); 
  }, []);

  const handlePremium = (isChecked) => {
    setPremium(isChecked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const selectedCategoryId = selectedOption ? selectedOption.value : null; 

    const formData = new FormData();
    formData.append("name", questionCategoryName);
    formData.append("categoryId", selectedCategoryId);
    formData.append("photo", photoSelect);
    formData.append("isPremium", premium ? 1 : 0);

   
  
    try {
      await dispatch(createDataQuestionCategory(formData));
      setQuestionCategoryName("");
      setSelectedOption(null);
      setPhotoSelect(null)
      setPremium(false)
      handleCloseModal();
      toast.success("Pyetja me kategori u shtua me sukses !");
    } catch (error) {
      console.log("Error submitting data:", error);
      toast.error("SubLiteratura nuk u shtua !");

    }
  };
  
  const handleOpenModal = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption); 
  };


  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setPhotoSelect(file);
  };

  return (
    <div>
      <button
        data-modal-target="popup-modal"
        data-modal-toggle="popup-modal"
        onClick={handleOpenModal}
        className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-2.5 py-2  hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
      >
        
        Shto Pytje me Kategori
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
          <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
          <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "360px" }}
          >
            <button
              type="button"
              onClick={handleCloseModal}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
            >
              <FaRegCircleXmark style={{ width: 20, height: 20 }} />
            </button>

            <div className="p-6 text-center">
              <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                {title}
              </h3>
              <form className="space-y-6 text-start" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="nameSubliterature"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {nameQuestionCategory}:
                  </label>
                  <input
                    type="text"
                    name="nameSubliterature"
                    id="nameSubliterature"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder=""
                    value={questionCategoryName}
                    onChange={(e) => setQuestionCategoryName(e.target.value)}
                    required
                  />
                </div>

                {categoryQuestionCategory && (
                  <div>
                    <label
                      htmlFor="categoryLiterature"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {categoryQuestionCategory}:
                    </label>
                    <Select
                      isClearable
                      name="categoryLiterature"
                      id="categoryLiterature"
                      value={selectedOption}
                      onChange={handleOptionChange}
                      options={literatureOptions.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    />
                  </div>
                )}
               
               {photoQuestionCategory && (
                  <div>
                    <label
                      htmlFor="photoSubliterature"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {photoQuestionCategory}:
                    </label>
                    <input
                      type="file"
                      name="photoSubliterature"
                      id="photoSubliterature"
                      accept=".png, .svg, .jpg"
                      onChange={handlePhotoChange}
                      required
                    />
                  </div>
                )}

                {premiumStatus && (
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Premium:
                    </label>
                    <div className="flex">
                      <button
                        type="button"
                        className={`mr-2 text-sm rounded-lg px-3 py-1 ${
                          premium
                            ? "bg-green-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handlePremium(true)}
                      >
                        Po
                      </button>
                      <button
                        type="button"
                        className={`text-sm rounded-lg px-3 py-1 ${
                          !premium
                            ? "bg-red-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handlePremium(false)}
                      >
                        Jo
                      </button>
                    </div>
                  </div>
                )}

                 <div className="flex justify-center">
                  <div className="flex items-center">
                    <button
                      data-modal-hide="popup-modal"
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      {submitButtonText}
                    </button>

                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={handleCloseModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Anulo
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default AddModalQuestionCategory;
