import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "../../api/axiosInstance";


export const fetchDataDrivingSchools = createAsyncThunk("api/fetchDataDrivingSchools", async () => {
  try {
    const response = await axiosInstace.get("/autoshkolla/getSchools"); 
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const fetchDataDrivingSchools2 = createAsyncThunk(
  "auth/fetchDataDrivingSchools2",
  async (id) => { 
    try {
      const response = await axiosInstace.get(`/autoshkolla/getSchoolByUserId/${id}`); 
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const createDataDrivingSchools = createAsyncThunk(
  "api/createDataDrivingSchools",
  async (createDataDrivingSchools) => {
    try {
      const response = await axiosInstace.post("/autoshkolla/createUserAndSchool", createDataDrivingSchools);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const updateDataDrivingSchools = createAsyncThunk(
  "api/updateDataDrivingSchools",
  async ({ id, updatedData }) => {
    try {
      const response = await axiosInstace.put(`/autoshkolla/updateSchool/${id}`, updatedData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteDataDrivingSchools = createAsyncThunk(
  "apiQuestion/deleteDataDrivingSchools",
  async (itemId, thunkAPI) => {
    try {
      await axiosInstace.delete(`/autoshkolla/deleteSchool/${itemId}`);
      return itemId;
    } catch (error) {
      throw new Error(error.response.data.message);

    }
  }
);

const apiDrivingSchoolsSlice = createSlice({
  name: "apiDrivingSchools",
  initialState: {
    data: [],
    loading: false,
    error: null,
    loadingCreate: false,
    loadingUpdate: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataDrivingSchools.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataDrivingSchools.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataDrivingSchools.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDataDrivingSchools2.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataDrivingSchools2.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataDrivingSchools2.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createDataDrivingSchools.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDataDrivingSchools.fulfilled, (state, action) => {
        state.loadingCreate = true;
        state.data.push(action.payload);
      })
      .addCase(createDataDrivingSchools.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDataDrivingSchools.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDataDrivingSchools.fulfilled, (state, action) => {
        state.loadingUpdate = !state.loadingUpdate;
      })
      .addCase(updateDataDrivingSchools.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDataDrivingSchools.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDataDrivingSchools.fulfilled, (state, action) => {
        const deletedItemId = action.payload;
        state.data = state.data.filter((item) => item.id !== deletedItemId);
        state.loading = false;
      })
      .addCase(deleteDataDrivingSchools.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const selectDriveSchool = (state) => state.apiDrivingSchools.data
export default apiDrivingSchoolsSlice.reducer;