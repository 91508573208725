import React from 'react'
import { TableUserDriveSchool } from '../../components'

const UserDriveSchool = () => {


  return (
    <div>
      <div className="m-2 md:m-10 p-2 md:p-6 bg-white rounded-3xl">
        <p className="text-xl mb-4 text-left">Kandidatet e Autoshkolles</p>
        <TableUserDriveSchool />
      </div>
    </div>
  )
}

export default UserDriveSchool