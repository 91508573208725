import React, { useEffect, useState } from 'react'
import { FaPencilAlt } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import { editAnswerQuestion, updateDataQuestions } from '../../../store/slices/apiQuestionSlice';
import { toast } from 'react-toastify';
const EditModalQuestion = ({ item, onClose }) => {
    const dispatch = useDispatch();
    const id = item.id
    const [isOpen, setIsOpen] = useState(false);
    const [question, setQuestion] = useState(item.question_text);
    const [score, setScore] = useState(item.score);
    const [photo, setPhoto] = useState(null);
    const [formData, setFormData] = useState(JSON.parse(item.answers))

    const handleEditItem = async (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('question', question);
        formData.append('score', score);
        formData.append('photo', photo);

        try {
            await dispatch(updateDataQuestions({ id, updateData: formData }))
            toast.success("Te dhenat jan ruajtur me sukses!");
        } catch (error) {
            console.log(error)
        }
    }

    const handleAnswerSubmit = (updatedAnswer) => {
        try {
            dispatch(editAnswerQuestion(updatedAnswer))
            toast.success("Te dhenat jan ruajtur me sukses!");
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <div>

            <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
                <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
                <div
                    className="relative bg-white rounded-lg shadow-lg"
                    style={{ width: "360px" }}
                >


                    <div className="p-6 text-center">
                        <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                            Editimi i pyetjeve
                        </h3>
                        <form className="space-y-6 text-start" onSubmit={handleEditItem}>
                            <div>
                                <label
                                    htmlFor="question"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Pyetja
                                </label>
                                <input
                                    type="text"
                                    name="question"
                                    id="question"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                    value={question}
                                    onChange={(e) => setQuestion(e.target.value)}
                                    required
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="score"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Piket
                                </label>
                                <input
                                    type="number"
                                    name="score"
                                    id="score"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                    value={score}
                                    onChange={(e) => setScore(e.target.value)}
                                    required
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="photo"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Photo:
                                </label>
                                <input
                                    type="file"
                                    name="photo"
                                    id="photo"
                                    accept=".png, .svg, .jpg"
                                    onChange={(e) => setPhoto(e.target.files[0])}
                                />
                            </div>

                            <div className="flex justify-center">
                                <div className="flex items-center">
                                    <button
                                        data-modal-hide="popup-modal"
                                        type="submit"
                                        className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                    >
                                        Edito Pytjen
                                    </button>

                                    <button
                                        data-modal-hide="popup-modal"
                                        type="button"
                                        onClick={() => onClose()}
                                        className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                    >
                                        Anulo
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className='mt-[20px]'>
                            {formData.map((item) => (
                                <EditAnswerForm key={item.answer_id} answer={item} onSubmit={handleAnswerSubmit} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}


const EditAnswerForm = ({ answer, onSubmit }) => {
    const [answerText, setAnswerText] = useState(answer.answer_text);
    const [isCorrectAnswer, setIsCorrectAnswer] = useState(answer.is_correct === 1);
    const [answerId, setAnswerId] = useState(answer.answer_id)
    const handleEditAnswer = (e) => {
        e.preventDefault();
        onSubmit({ answerText, isCorrectAnswer, answerId });
    };

    return (
        <form key={answer.answer_id} onSubmit={handleEditAnswer}>
            <input type='hidden' name="answerId" value={answerId} onChange={(e) => setAnswerId(e.target.value)} />
            <div className="flex">
                <textarea
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white mb-2"
                    value={answerText}
                    onChange={(e) => setAnswerText(e.target.value)}
                />
                <div className="flex items-center ml-4">
                    <input
                        type="checkbox"
                        className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={isCorrectAnswer}
                        onChange={(e) => setIsCorrectAnswer(e.target.checked)}
                    />
                </div>
            </div>
            <button
                type="submit"
                className='text-white mb-1 bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600'
            >
                Edito Pergjigjjen
            </button>
        </form>
    );
};

export default EditModalQuestion