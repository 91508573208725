import { createSlice } from "@reduxjs/toolkit";

const tableSlice = createSlice({
  name: "table",
  initialState: {
    data: [], 
    currentPage: 1, 
    itemsPerPage: 8, 
    searchQuery: "", 
  },
  reducers: {
    updateData: (state, action) => {
      state.data = action.payload;
    },
    updatePage: (state, action) => {
      state.currentPage = action.payload;
    },
    updateSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    updateDataCategories: (state, action) => {
      state.dataCategories = action.payload;
    },
    setInsufficientPrivilegesError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    updateDateRange: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    updateSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },

  },
});

export const { updateData, updatePage, updateSearchQuery, updateDataCategories, setInsufficientPrivilegesError, clearError, updateDateRange, updateSelectedDate } = tableSlice.actions;

export default tableSlice.reducer;
