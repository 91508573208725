import React, { useState } from "react";
import { openModal, closeModal } from "../../store/slices/editModalSlice";
import { useDispatch, useSelector } from "react-redux";
import InfoModal from "../Modals/InfoModal";
import DeleteModalQuestions from "../Modals/DeleteModal/DeleteModalQuestions";
import { openModalDelete } from "../../store/slices/deleteModalQuestionsSlice";
import { FaPencilAlt } from "react-icons/fa";
import EditModalQuestion from "../Modals/EditModal/EditModalQuestion";

const TableItemsQuestions = ({ dataQuestions, baseUrl }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState();
  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleOpenModal = (id) => {
    dispatch(openModal(id));
  };


  const handleDeleteItem = (id) => {
    dispatch(openModalDelete(id));
  };

  const openModal = (item) => {
    setItem(item)
  }
  const onClose = () => {
    setOpen(!open)
  }
  return (
    <>
      {open &&
        <EditModalQuestion item={item} onClose={onClose} />
      }
      {dataQuestions.map((item) => (
        <tr
          key={item.id}
          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          <td className="px-6 py-4 font-medium text-gray-900  ">{item.question_text}</td>
          <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {item.testName}
          </td>
          <td className="px-6 py-4 font-medium whitespace-nowrap dark:text-white">
            {item.testCategoryName}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">{item.score}</td>
          <td className="px-6 py-4">
            <a href={`${baseUrl}/${item.photo_path}`} target="_blank" rel="noopener noreferrer">
              <img src={`${baseUrl}/${item.photo_path}`}
                alt="Question"
                className="w-12 h-12 object-cover"
              />
            </a>
          </td>
          <td className="px-6 py-4 w-40">
            <button className="text-sucess mx-1" onClick={() => {
              setOpen(!open)
              openModal(item)
            }} >
              Edito
            </button>
            <button className="text-danger mx-1" onClick={() => handleDeleteItem(item.id)}>
              <DeleteModalQuestions
                id={item.id}
                onCancel={handleCloseModal}

              />
            </button>
          </td>
        </tr>
      ))}
    </>
  );
};

export default TableItemsQuestions;
