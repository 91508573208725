import SearchBar from '../SearchBar/SearchBar'
import AddModalQuestionCategoryList from '../Modals/AddModalQuestionCategoryList'
import { selectQuestionWithCategory, fetchQuestionWithAnswerCategory } from '../../store/slices/apiQuestionCategorySlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Pagination } from '../../components'
import TableItemsQuestionWithCategory from '../TableItemsQuestionWithCategory/TableItemsQuestionWithCategory'
import { updateSearchQuery } from '../../store/slices/tableSlice'
const TableListQuestionCategory = () => {
  const dispatch = useDispatch()
  const dataQuestion = useSelector((state) => state.apiQuestionCategory.dataQuestion);
  const loadingCreate = useSelector((state) => state.apiQuestion.loadingCreate);
  const loadingDelete = useSelector((state) => state.apiQuestionCategory.deleteLoading);
  const loadingUpdateAnswer = useSelector((state) => state.apiQuestionCategory.loadingUpdateQuestion)
  const loadingEditAnswer = useSelector((state) =>  state.apiQuestionCategory.editAnswerQuestion)
  const [searchInput, setSearchInput] = useState("");
  const { currentPage, itemsPerPage, searchQuery } = useSelector(
    (state) => state.table
  );
  const backendBaseUrl = 'https://back.autoshkollaime.com';

  const filteredData = dataQuestion.filter(item => {
    const catName = item.category_name &&  item.category_name.toLowerCase().includes(searchInput.toLowerCase())
    const question = item.category_question_name && item.category_question_name.toLowerCase().includes(searchInput.toLowerCase())
    const text = item.question_text && item.question_text.toLowerCase().includes(searchInput.toLowerCase())
    return catName || question || text
  })

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = filteredData.slice(firstIndex, lastIndex);

  
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };

  useEffect(() => {
    dispatch(fetchQuestionWithAnswerCategory())
  }, [loadingDelete, loadingCreate, loadingUpdateAnswer, loadingEditAnswer])
  
  return (
    <div className="p-3">
      <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
        <SearchBar 
        searchInput={searchInput}
        handleSearchInputChange={handleSearchInputChange}
        handleSearchSubmit={handleSearchSubmit}
        />
        <div>
          <button className="text-danger mx-2" alt="you can edit here">
            <AddModalQuestionCategoryList 
             title="Shto një pyetje me kategori"
             submitButtonText="Shto pyetjen"
            />
          </button>
        </div>
      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
            <th scope="col" className="px-6 py-3">
                Emri i Pyetjes
              </th>
             <th scope="col" className="px-6 py-3">
                Kategoria
              </th>
              <th scope="col" className="px-6 py-3">
                Sub Kategoria
              </th>
              <th scope="col" className="px-6 py-3">
                Ikona
              </th>
            
              <th scope="col" className="px-6 py-3">
                Piket
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
          <TableItemsQuestionWithCategory
            dataQuestionCategory={currentItems}
            baseUrl={backendBaseUrl}
          />
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          filteredData={filteredData}
        />
      </div>
    </div>
  )
}

export default TableListQuestionCategory