import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSearchQuery } from "../../store/slices/tableSlice";
import { SearchBar, Pagination, TableItemsQuestions } from "../../components";
import AddModalQuestions from "../Modals/AddModalQuestions";
import { closeModal } from "../../store/slices/deleteModalSlice";
import { fetchDataQuestions } from "../../store/slices/apiQuestionSlice";
import "react-toastify/dist/ReactToastify.css";


const TableQuestions = () => {
  const dispatch = useDispatch();
  const loadingUpdate = useSelector((state) => state.apiQuestion.loadingUpdate)
  const loadingCreate = useSelector((state) => state.apiQuestion.loadingCreateQuestion)
  const loadingUpdateQuestion = useSelector((state) => state.apiQuestion.editAnswerQuestion)
  const loadingUpdateAnswer = useSelector((state) => state.apiQuestion.loadingUpdateAnswer)
  const { currentPage, itemsPerPage, searchQuery, error } = useSelector(
    (state) => state.table
  );

  const backendBaseUrl = 'https://back.autoshkollaime.com';
  const dataQuestions = useSelector((state) => state.apiQuestion.data);
  const [searchInput, setSearchInput] = useState("");

  const filteredData = dataQuestions.filter((item) => {
    const questionText = item.question_text && item.question_text.toLowerCase().includes(searchQuery.toLowerCase());
    const testCatName = item.testCategoryName && item.testCategoryName.toLowerCase().includes(searchQuery.toLowerCase());
    const testName  = item.testName && item.testName.toLowerCase().includes(searchQuery.toLowerCase());

    return questionText || testCatName || testName
  });
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = filteredData.slice(firstIndex, lastIndex);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    dispatch(fetchDataQuestions());
  }, [loadingUpdate, loadingCreate, loadingUpdateQuestion, loadingUpdateAnswer]);
  return (
    <div className="p-3">
      {error && <div className="text-red-500">{error}</div>}
      <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
        <SearchBar
          searchInput={searchInput}
          handleSearchInputChange={handleSearchInputChange}
          handleSearchSubmit={handleSearchSubmit}
        />
        <div>
          <button className="text-blue-500 mx-2">
            <AddModalQuestions
              title="Shto një pyetje"
              submitButtonText="Shto pyetjen"
              onCancel={handleCloseModal}
              exams="Zgjedh testin"
              questionsCategory="Zgjedh pyetjen me kategori"
              questionsAnswer1="Shkruaj pergjigjet"
              questionsAnswer2="Saktë"
              questionsAnswer3="Shkruaj pergjigjen e 3"
              score="Piket"
            />
          </button>
        </div>
      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Pyetja
              </th>
              <th scope="col" className="px-6 py-3">
                Testi
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Kategoria e Testit
              </th>

              <th scope="col" className="px-6 py-3">
                Pikët
              </th>
              <th scope="col" className="px-6 py-3">
                Foto
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <TableItemsQuestions
              dataQuestions={currentItems}
              baseUrl={backendBaseUrl}
            />
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          filteredData={filteredData}
        />
      </div>
    </div>
  );
};

export default TableQuestions;