
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "../../api/axiosInstance";

export const fetchDataCategories = createAsyncThunk("api/fetchDataCategories", async () => {
  try {
    const response = await axiosInstace.get("/categories");
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const createDataCategories = createAsyncThunk(
  "api/createDataCategories",
  async (createDataCategories) => {
    try {
      const response = await axiosInstace.post("/categories/addCategories", createDataCategories);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const updateDataCategories = createAsyncThunk(
  "api/updateTest",
  async ({ id, updatedData }) => {
    try {
      const response = await axiosInstace.put(`/categories/updateCategory/${id}`, updatedData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteDataCategories = createAsyncThunk(
  "api/deleteCategories",
   async (itemId, thunkAPI) => {
    try {
      await axiosInstace.delete(`/categories/deleteCategory/${itemId}`);
      return itemId;
    } catch (error) {
      throw new Error(error.response.data.message);

    }
  }
);

const apiCategorySlice = createSlice({
  name: "apiCategory",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createDataCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDataCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createDataCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDataCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDataCategories.fulfilled, (state, action) => {
        const updatedData = action.payload;
        const index = state.data.findIndex((item) => item.id === updatedData.id);
        if (index !== -1) {
          state.data[index] = updatedData;
        }
        state.loading = false;
      })
      .addCase(updateDataCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDataCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDataCategories.fulfilled, (state, action) => {
        const deletedItemId = action.payload;
        state.data = state.data.filter((item) => item.id !== deletedItemId);
        state.loading = false;
      })
      .addCase(deleteDataCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default apiCategorySlice.reducer;
