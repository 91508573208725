import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../store/slices/addModalSlice";
import { FaCirclePlus,FaRegCircleXmark } from "react-icons/fa6";
import { options } from "../../data/dataTable";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createDataCategories, fetchDataCategories } from "../../store/slices/apiCategorySlice";
const AddModal = ({
  title,
  submitButtonText,
  inputLabel1,
  inputLabel2,
  inputLabel3,
  inputLabel4,
  premiumStatus
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.addModal.isOpen);

  const [nameValue, setNameValue] = useState("");
  const [subtitleValue1, setSubtitleValue1] = useState("");
  const [subtitleValue2, setSubtitleValue2] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [premiumCategory, setPremiumCategory] = useState(false);

  const handlePremiumCategory= (isChecked) => {
    setPremiumCategory(isChecked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: nameValue,
      subtitle1: subtitleValue1,
      subtitle2: subtitleValue2,
      isPremium: premiumCategory  ? 1 : 0
    };
    try {
      await dispatch(createDataCategories(data));
      setNameValue("");
      setSubtitleValue1("");
      setSubtitleValue2("");
      setPremiumCategory("");
      handleCloseModal();      
      toast.success("Kategoria u shtua me sukses !");
      dispatch(fetchDataCategories())
    } catch (error) {
      console.log("Error submitting data:", error);
    }     


  };
 
  const handleOpenModal = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleOptionChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
  };

  return (
    <div>
        <button
        data-modal-target="popup-modal"
        data-modal-toggle="popup-modal"
        onClick={handleOpenModal}
        className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-2.5 py-2   hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
      >
        Shto Kategorinë
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
          <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
          <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "360px" }}
          >
            <button
              type="button"
              onClick={handleCloseModal}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
            >
              <FaRegCircleXmark style={{ width: 20, height: 20 }} />
            </button>

            <div className="p-6 text-center">
              <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                {title}
              </h3>
              <form className="space-y-6 text-start" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="input1"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {inputLabel1}:
                  </label>
                  <input
                    type="text"
                    name="input1"
                    id="input1"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder=""
                    value={nameValue}
                    onChange={(e) => setNameValue(e.target.value)}
                    required
                  />
                </div>

                  <div>
                    <label
                      htmlFor="input2"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {inputLabel2}:
                    </label>
                    <textarea
                      name="examsName"
                      id="examsName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white resize-y"
                      placeholder=""
                      value={subtitleValue1}
                      onChange={(e) => setSubtitleValue1(e.target.value)}
                      required
                      rows="4" 
                    />
                  </div>


                  <div>
                    <label
                      htmlFor="input4"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {inputLabel4}:
                    </label>
                   <textarea
                      name="examsName"
                      id="examsName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white resize-y"
                      placeholder=""
                      value={subtitleValue2}
                      onChange={(e) => setSubtitleValue2(e.target.value)}
                      required
                      rows="4" 
                    />
                  </div>

                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Premium:
                    </label>
                    <div className="flex">
                      <button
                        type="button"
                        className={`mr-2 text-sm rounded-lg px-3 py-1 ${
                          premiumCategory
                            ? "bg-green-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handlePremiumCategory(true)}
                      >
                        Po
                      </button>
                      <button
                        type="button"
                        className={`text-sm rounded-lg px-3 py-1 ${
                          !premiumCategory
                            ? "bg-red-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handlePremiumCategory(false)}
                      >
                        Jo
                      </button>
                    </div>
                  </div>

                <div className="flex justify-center">
                  <div className="flex items-center">
                    <button
                      data-modal-hide="popup-modal"
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      {submitButtonText}
                    </button>

                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={handleCloseModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Anulo
                    </button>
                  </div>
               
                </div>
              </form>
            </div>
          </div>
        </div>
        
      )}
         <ToastContainer />
    </div>
    
  );
};

export default AddModal;



