import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const deleteModalFinancialBalanceSlice = createSlice({
  name: "deleteModalFinancialBalance",
  initialState,
  reducers: {
    openModalDelete: (state, action) => {
      const itemId = action.payload;
      state[itemId] = true;
    },
    closeModalDelete: (state, action) => {
      const itemId = action.payload;
      state[itemId] = false;
    },
  },
});

export const { openModalDelete, closeModalDelete } = deleteModalFinancialBalanceSlice.actions;
export default deleteModalFinancialBalanceSlice.reducer;
