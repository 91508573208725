import React from "react";
import DeleteModal from "../Modals/DeleteModal";
import EditModal from "../Modals/EditModal";
import { closeModal, openModal } from "../../store/slices/editModalSlice";
import { useDispatch, useSelector } from "react-redux";
import InfoModal from "../Modals/InfoModal";
import { openModalDelete } from "../../store/slices/deleteModalCategorySlice";
import DeleteModalCategory from "../Modals/DeleteModal/DeleteModalCategory";
import EditModalCategory from "../Modals/EditModal/EditModalCategory";

const TableItemsCategory = ({ dataCategories, showCity, showCategory }) => {
  const dispatch = useDispatch();
  const editModalState = useSelector((state) => state.editModalCategory); 

  const handleEditCategory = (categoryName) => {
  };
  const handleCloseModal = () => {
    dispatch(closeModal());

  };

  const handleOpenModal = (id) => {
    dispatch(openModal(id));
  };
  const handleEditItem = (id) => {
    dispatch(openModal(id));
  };

  const handleDeleteItem = (id) => {
    dispatch(openModalDelete(id));
  };

  function getYesNoTextFromBoolean(value) {
    return value ? "Po" : "Jo";
  }

  return (
    <>
      {dataCategories.map((item) => (
        <tr
          key={item.id}
          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {item.name}
          </td>
          <td className="px-6 py-4 ">{item.subtitle1}</td>
          <td className="px-6 py-4 ">{item.subtitle2}</td>
          <td className="px-6 py-4">{getYesNoTextFromBoolean(item.isPremium)}</td>
          <td className="px-6 py-4 w-40 ">
            <button className="text-blue-500 mr-2">
              <InfoModal
                title="Info rreth kategorise"
                onCancel={handleCloseModal}
                inputLabel1="Lloji i kategorisë"
                inputLabel2="Emri 1"
                inputLabel4="Emri 2"
              />
            </button>
            <button className="text-sucess mx-1" onClick={() => handleEditItem(item.id)}>
              <EditModalCategory
               id={item.id}
               title="Edito një kategori"
               submitButtonText="Edito kategorinë"
               categoryName="Emri i kategorisë"
               subtitleName="Emri 1"
               subtitleName2="Emri 2"
               premiumStatus="A eshte premium"
               isOpen={editModalState[item.id] === true}
              />
            </button>
            <button className="text-danger mx-1" onClick={() => handleDeleteItem(item.id)}>
              <DeleteModalCategory 
                id={item.id} 
              />
            </button>
          </td>
        </tr>
      ))}
    </>
  );
};

export default TableItemsCategory;
