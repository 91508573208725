import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSearchQuery } from "../../store/slices/tableSlice";
import { SearchBar, Pagination } from "../../components";
import { fetchDebtAllUser, getAllPaymet, payDebtDriveSchool, resetPay, selectAllPayment } from "../../store/slices/debtSlice";
import { FaRegCircleXmark } from "react-icons/fa6";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



const TableFinancialBalance = () => {
  const dispatch = useDispatch();
  const { currentPage, itemsPerPage, searchQuery } = useSelector((state) => state.table);
  const debt = useSelector((state) => state.debt.data);
  const payDebtStatus = useSelector((state) => state.debt.payDebtStatus)
  const loadingReset = useSelector((state) => state.debt.loadingResetPay)

  const [searchInput, setSearchInput] = useState("");
  const [userId, setUserId] = useState("")
  const [amount, setAmount] = useState("")
  const [open, setOpen] = useState(false)
  const [openResetModal, setOpenResetModal] = useState(false)
  const [id, setId] = useState(0)

  const filteredData = debt?.filter((item) => {
    const name = item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase());
    const email = item.email && item.email.toLowerCase().includes(searchQuery.toLowerCase());
    const phoneNumber = item.phoneNumber && item.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase());
    const city = item.city && item.city.toLowerCase().includes(searchQuery.toLowerCase());
    return name || email || phoneNumber || city
  });

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = filteredData.slice(firstIndex, lastIndex);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };



  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };


  useEffect(() => {
    dispatch(fetchDebtAllUser())
    dispatch(getAllPaymet())
  }, [payDebtStatus, loadingReset]);

  const onSubmit = (e) => {
    e.preventDefault()
    const data = { userId, amount }
    try {
      dispatch(payDebtDriveSchool(data))
      setAmount("")
      toast.success("Pagesa shkoi me sukses")
    } catch (error) {
      console.log(error)
    }
  }

  const resetPayment = (id) => {
    try {
      dispatch(resetPay(id))
      toast.success("Te dhenat jane freskuar me sukses");
    } catch (error) {
      console.log(error)
    }
  }

  const openResetModalFunction = (id) => {
    setId(id)
    setOpenResetModal(!openResetModal)
  }

  const paymentArray = (data) => {
    const parsData = JSON.parse(data)
    const totalCalculationOfDriveSchool = parsData?.reduce((totals, payment) => {
      if (payment.isActive === 1) {
        return totals + payment.amount;
      }
      return totals;
    }, 0);
    return totalCalculationOfDriveSchool
  }

  const debtArray = (data) => {
    const parsData = JSON.parse(data)
    const totalPayDebt = parsData?.reduce((total, debt) => {
      return total + debt.amount;
    }, 0);
    return totalPayDebt
  }

  const calcaulateTotalPriceAdmin = (data) => {
    const parsData = JSON.parse(data);
    const totalProfitAdmin = parsData?.filter(item => item.isActive === 1)
    return totalProfitAdmin.reduce((total, profit) => {
      return total + profit.adminPrice
    }, 0)
  }

  const calculateAdminPrice = (data) => {
    const parsData = JSON.parse(data);
    const totalProfitAdmin = parsData?.filter(item => item.isActive === 1)
    return totalProfitAdmin.reduce((total, profit) => {
      if (profit.cash === 1) {
        return total + profit.adminPrice
      } else {
        return total - profit.schoolPrice
      }
    }, 0)
  }


  const calculateBalance = (paymentData, debtData) => {
    const totalPayment = calculateAdminPrice(paymentData);
    const totalDebt = debtArray(debtData);
    const balance = totalPayment - totalDebt;
    return balance || 0;
  };


  const debtBank = (data) => {
    const parsData = JSON.parse(data)
    const debt = parsData.filter((item) => item.cash === 0)
    return debt.reduce((total, debt) => {
      return total + debt.amount
    }, 0)
  }

  function makePositive(number) {
    if (number < 0) {
      return -number;
    } else {
      return number;
    }
  }


  return (
    <div className="p-3">
      <div className="flex items-center justify-be tween pb-4 bg-white dark:bg-gray-900">
        <SearchBar
          searchInput={searchInput}
          handleSearchInputChange={handleSearchInputChange}
          handleSearchSubmit={handleSearchSubmit}
        />

      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Emri i Autoshkollës
              </th>
              <th scope="col" className="px-6 py-3">
                Emaili
              </th>
              <th scope="col" className="px-6 py-3">
                Numri i telefonit
              </th>
              <th scope="col" className="px-6 py-3">
                Qyteti
              </th>
              <th scope="col" className="px-6 py-3">
                Totali i kandidatve ne euro
              </th>
              <th scope="col" className="px-6 py-3">
                Parat permes bankes
              </th>
              <th scope="col" className="px-6 py-3">
                Paguar me Cash
              </th>
              <th scope="col" className="px-6 py-3">
                Fitimi total i adminit
              </th>
              <th scope="col" className="px-6 py-3">
                Borgji i mbetur
              </th>
              <th scope="col" className="px-6 py-3">
                Reseto
              </th>
              <th scope="col" className="px-6 py-3">
                Paguaj
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => {
              return (
                <tr
                  key={item.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {item.name}
                  </td>
                  <td className="px-6 py-4">{item.email}</td>
                  <td className="px-6 py-4">{item.phoneNumber} </td>
                  <td className="px-6 py-4">{item.city}</td>
                  <td className="px-6 py-4">
                    {paymentArray(item.payments)} €
                  </td>
                  <td className="px-6 py-4">
                    {debtBank(item.payments)}
                  </td>
                  <td className="px-6 py-4">
                    {debtArray(item.debts) && `${debtArray(item.debts)}`} €
                  </td>

                  <td className="px-6 py-4">
                    {calcaulateTotalPriceAdmin(item.payments)} €
                  </td>
                  <td className="px-6 py-4">
                  {calculateBalance(item.payments, item.debts) > 0 ? "-" : "+"}  {makePositive(calculateBalance(item.payments, item.debts))} €
                  </td>
                  <td onClick={() => {

                  }} className="px-6 py-4">
                    <button className="bg-red-500 rounded-full text-white px-4 py-2" onClick={() => openResetModalFunction(item.user_id)}>
                      Reseto
                    </button>
                  </td>

                  <td onClick={() => {
                    setOpen(!open)
                    setUserId(item.user_id)
                  }} className="px-6 py-4">
                    <button className="bg-blue-500 rounded-full text-white px-4 py-2">
                      Paguaj
                    </button>
                  </td>

                </tr>
              )
            })}
          </tbody>
        </table>

        <Pagination currentPage={currentPage} itemsPerPage={itemsPerPage} filteredData={filteredData} />
      </div>
      <div className="flex flex-end">
        <div className='py-5 px-8 rounded-[15px] bg-red-500 mt-5'>
          <p className='text-white'>Mosbarazimi <span className='text-black font-bold'>(    {debt.map((item) => calculateBalance(item.payments, item.debts)).reduce((total, debt) => {
            return total + debt
          }, 0)} €)</span></p>
        </div>
      </div>

      {open && <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
        <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
        <div
          className="relative bg-white rounded-lg shadow-lg"
          style={{ width: "420px" }}
        >
          <button
            type="button"
            onClick={() => setOpen(!open)}
            className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
          >
            <FaRegCircleXmark style={{ width: 20, height: 20 }} />
          </button>

          <div className="p-6 text-center">
            <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
              Paguaj
            </h3>
            <form className="space-y-6 text-start" onSubmit={onSubmit} >

              <div>
                <label
                  htmlFor="input2"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Shuma
                </label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder=""
                  required
                />
                <div className="flex justify-center">
                  <div className="flex items-center mt-5">
                    <button
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Paguaj
                    </button>

                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={() => setOpen(!open)}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Anulo
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>}


      {openResetModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
          <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
          <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "420px" }}
          >
            <button
              type="button"
              onClick={() => setOpenResetModal(!openResetModal)}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
            >
              <FaRegCircleXmark style={{ width: 20, height: 20 }} />
            </button>

            <div className="p-6 text-center">
              <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                A jeni i sigurt?
              </h3>
              <button className="bg-red-500 rounded-full text-white px-4 py-2" onClick={() => resetPayment(id)}>
                Reseto
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableFinancialBalance;
