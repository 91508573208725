import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const deleteModalPackageSlice = createSlice({
  name: "deleteModalPackage",
  initialState,
  reducers: {
    openModalDelete: (state, action) => {
      const itemId = action.payload;
      state[itemId] = true;
    },
    closeModalDelete: (state, action) => {
      const itemId = action.payload;
      state[itemId] = false;
    },
  },
});

export const { openModalDelete, closeModalDelete } = deleteModalPackageSlice.actions;
export default deleteModalPackageSlice.reducer;
