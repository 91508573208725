import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const editModalTextLiteratureSlice = createSlice({
  name: "editModalTextLiterature",
  initialState,
  reducers: {
    openModal: (state, action) => {
      const itemId = action.payload;
      state[itemId] = true;
    },
    closeModal: (state, action) => {
      const itemId = action.payload;
      state[itemId] = false;
    },
  },
});

export const { openModal, closeModal } = editModalTextLiteratureSlice.actions;

export default editModalTextLiteratureSlice.reducer;