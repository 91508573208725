import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const editModalLiteratureSlice = createSlice({
    name: "editModalLiterature",
    initialState,
    reducers: {
        openModal: (state, action) => {
            const itemId = action.payload;
             state[itemId] = true;
          },
          closeModal: (state, action) => {
            const itemId = action.payload;
            state[itemId] = false;
          },
    },
});

export const { openModal, closeModal } = editModalLiteratureSlice.actions;
export default editModalLiteratureSlice.reducer;
