import { createSlice } from "@reduxjs/toolkit";

const addModalPackageSlice = createSlice({
  name: "addModalPackage",
  initialState: {
    isOpen: false,
    error: null,
  },
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
      state.error = null;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.error = null;
    },
  },
});

export const { openModal, closeModal } = addModalPackageSlice.actions;
export default addModalPackageSlice.reducer;
