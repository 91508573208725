import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../store/slices/addModalSlice";
import { FaRegCircleXmark } from "react-icons/fa6";
import { fetchDataCategories } from "../../store/slices/apiCategorySlice";
import { createDataLiterature, fetchDataLiterature } from "../../store/slices/apiLiteratureSlice";
import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const AddModalLiterature = ({
  title,
  submitButtonText,
  onCancel,
  onSubmit,
  inputLabel2,
  premiumStatus
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpen = useSelector((state) => state.addModal.isOpen);

  const [selectedOption, setSelectedOption] = useState(null);
  const categoryOptions = useSelector((state) => state.apiCategory.data);
  const [LiteratureName, setLiteratureName] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [premiumLiterature, setPremiumLiterature] = useState(false);
  const [desc, setDesc] = useState("")

  useEffect(() => {
    dispatch(fetchDataCategories());
  },[]);


  const handlePremiumLiterature = (isChecked) => {
    setPremiumLiterature(isChecked);
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setSelectedPhoto(file);
  };

  const handleOpenModal = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedCategoryId = selectedOption ? selectedOption.value : null;

    const formData = new FormData();
    formData.append("name", LiteratureName);
    formData.append("categoryId", selectedCategoryId);
    formData.append("photo", selectedPhoto);
    formData.append("isPremium", premiumLiterature ? 1 : 0);
    formData.append("desc", desc)

    try {
      await dispatch(createDataLiterature(formData));
      setLiteratureName("");
      setSelectedPhoto(null);
      setSelectedOption(null);
      setDesc("")
      setPremiumLiterature(false); 
      handleCloseModal();
      toast.success("Literatura u shtua me sukses!");
      dispatch(fetchDataLiterature());
    } catch (error) {
      toast.error("Literatura nuk u shtua!");
      console.log("Error submitting data:", error);
    }
  };

  return (
    <div>
      <button
        className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-2.5 py-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
        data-modal-target="popup-modal"
        data-modal-toggle="popup-modal"
        onClick={handleOpenModal}
      >
        Shto Literaturën
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
          <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
          <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "360px" }}
          >
            <button
              type="button"
              onClick={handleCloseModal}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
            >
              <FaRegCircleXmark style={{ width: 20, height: 20 }} />
            </button>

            <div className="p-6 text-center">
              <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                {title}
              </h3>
              <form className="space-y-6 text-start" onSubmit={handleSubmit}>   
              <div>
                  <label
                    htmlFor="LiteratureName"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Emrin e literaturës:
                  </label>
                  <input
                    type="text"
                    name="LiteratureName"
                    id="LiteratureName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Shkruaj emrin e literatures"
                    value={LiteratureName}
                    onChange={(e) => setLiteratureName(e.target.value)}
                  />
                </div>

                <div>
                  <label
                    htmlFor="desc"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Pershkrimi
                  </label>
                  <textarea
                    name="desc"
                    id="desc"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Shkruaj emrin e literatures"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </div>
                {inputLabel2 && (
                  <div>
                    <label
                      htmlFor="input2"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {inputLabel2}:
                    </label>
                    <Select
                      isClearable
                      name="input2"
                      id="input2"
                      value={selectedOption}
                      onChange={handleOptionChange}
                      options={categoryOptions.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      />
                  </div>
                )}

                {premiumStatus && (
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Premium:
                    </label>
                    <div className="flex">
                      <button
                        type="button"
                        className={`mr-2 text-sm rounded-lg px-3 py-1 ${
                          premiumLiterature
                            ? "bg-green-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handlePremiumLiterature(true)}
                      >
                        Po
                      </button>
                      <button
                        type="button"
                        className={`text-sm rounded-lg px-3 py-1 ${
                          !premiumLiterature
                            ? "bg-red-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handlePremiumLiterature(false)}
                      >
                        Jo
                      </button>
                    </div>
                  </div>
                )}
                
                <div>
                  <label
                    htmlFor="photo"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Zgjedh ikonën:
                  </label>
                  <input
                    type="file"
                    name="photo"
                    id="photo"
                    accept=".png, .svg, .jpg"
                    onChange={handlePhotoChange}
                    required
                  />
                </div>

                <div className="flex justify-center">
                  <div className="flex items-center">
                    <button
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      {submitButtonText}
                    </button>

                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={handleCloseModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Anulo
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddModalLiterature;
