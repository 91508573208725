import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSearchQuery } from "../../store/slices/tableSlice";
import { SearchBar, Pagination, TableItemsLiterature } from "../../components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddModalLiterature from "../Modals/AddModalLiterature";
import { fetchDataLiterature } from "../../store/slices/apiLiteratureSlice";

const TableLiterature = () => {
  const dispatch = useDispatch();
  const loadingUpdateLit = useSelector((state) => state.apiLiterature.loadingUpdate)
  const { currentPage, itemsPerPage, searchQuery } = useSelector(
    (state) => state.table
  );

  const backendBaseUrl = 'https://back.autoshkollaime.com';
  const dataLiterature = useSelector((state) => state.apiLiterature.data);
  const [searchInput, setSearchInput] = useState("");


  const filteredData = dataLiterature.filter((item) =>
    item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );


  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = filteredData.slice(firstIndex, lastIndex);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };


  useEffect(() => {
    dispatch(fetchDataLiterature());
  }, [loadingUpdateLit]);

  return (
    <div className="p-3">
      <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
        <SearchBar
          searchInput={searchInput}
          handleSearchInputChange={handleSearchInputChange}
          handleSearchSubmit={handleSearchSubmit}
        />
        <div>
          <button className="text-blue-500 mx-2" alt="you can edit here">
            <AddModalLiterature
              title="Shto një literaturë"
              submitButtonText="Shto literaturën"
              inputLabel2="Zgjedh kategorinë"
              premiumStatus="A eshte premium"
            />
          </button>
        </div>
      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Emri i Literaturës
              </th>
              <th scope="col" className="px-6 py-3">
                Kategoria
              </th>
              <th scope="col" className="px-6 py-3">
                Premium
              </th>
              <th scope="col" className="px-6 py-3">
                Ikona
              </th>
              <th scope="col" className="px-6 py-3">
                Pershkrimi
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>

            </tr>
          </thead>
          <tbody>
            <TableItemsLiterature
              dataLiterature={currentItems}
              baseUrl={backendBaseUrl}
            />
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          filteredData={filteredData}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default TableLiterature;