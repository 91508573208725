import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal,closeModal } from "../../store/slices/editModalSlice";
import { openModalDelete, closeModalDelete } from "../../store/slices/deleteModalQuestionsCategorySlice";
import InfoModal from "../Modals/InfoModal";
import EditModalQuestionsCategory from "../Modals/EditModal/EditModalQuestionsCategory";
import DeleteModalQuestionsCategory from "../Modals/DeleteModal/DeleteModalQuestionsCategory";

const TableItemsQuestionsCategory = ({ dataQuestionCategory, baseUrl }) => {
  const dispatch = useDispatch();
  const loadinUpdate = useSelector((state) => state.apiQuestionCategory.loadingUpdate)
  const deleteModalState = useSelector((state) => state.deleteModalExams);
  const editModalState = useSelector((state) => state.editModalQuestionsCategory); 

  const handleCloseModal = (modalType) => {
    if (modalType === "edit") {
      dispatch(closeModal()); 
    } else if (modalType === "delete") {
      dispatch(closeModalDelete()); 
    } else if (modalType === "info") {
      dispatch(closeModalDelete()); 
    }
  };

  const handleOpenModal = (id) => {
    dispatch(openModal(id));
  };

  const handleEditItem = (id) => {
    dispatch(openModal(id));
  };

  const handleDeleteItem = (id) => {
    dispatch(openModalDelete(id));
  };


  function getYesNoTextFromBoolean(value) {
    return value ? "Po" : "Jo";
  }
  return (
    <>
      {dataQuestionCategory.map((item) => (
        <tr
          key={item.id}
          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {item.name}
          </td>
          <td className="px-6 py-4">{item.categoryName}</td>
          <td className="px-6 py-4">
            <a href={`${baseUrl}/${item.icon}`} target="_blank" rel="noopener noreferrer">
              <img src={`${baseUrl}/${item.icon}`}
                alt="Question"
                className="w-12 h-12 object-cover"
              />
            </a>
          </td>
          <td className="px-6 py-4">{getYesNoTextFromBoolean(item.isPremium)}</td>



          <td className="px-6 py-4 w-40">
            <button className="text-blue-500 mr-2">
              <InfoModal
                title="Info rreth testit"
                name="Emri i testit"
                categoryInInfo="Kategoria e testit"
              />
            </button>
            <button className="text-sucess mx-1" onClick={() => handleEditItem(item.id)}>
              <EditModalQuestionsCategory
                id={item.id}
                title="Edito një pyetje me kategori"
                submitButtonText="Edito pyetjen me kategori "
                questionsName="Emri i pyetjes"
                CategoryName="Lloji i kategorisë"
                isOpen={editModalState[item.id] === true}
              />
            </button>
            <button className="text-danger mx-1" onClick={() => handleDeleteItem(item.id)}>
              <DeleteModalQuestionsCategory 
                id={item.id} 
              />
            </button>
          </td>
        </tr>
      ))}
    </>
  );
};

export default TableItemsQuestionsCategory;
