import React from "react";
import { TableExams } from "../../components";

const Exams = () => {
  return (
    <div>
      <div className="m-2 md:m-10 p-2 md:p-6 bg-white rounded-3xl">
        <p className="text-xl mb-4 text-left">Lista e Testeve</p>
        <TableExams />
      </div>
    </div>
  );
};

export default Exams;