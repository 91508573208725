import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchDataLogin = createAsyncThunk("api/fetchDataLogin", async () => {
  const response = await axios.get("/api/categories");
  return response.data;
});

export const createDataLogin = createAsyncThunk(
  "api/createDataLogin",
  async (requestData) => {
    const response = await axios.post("/api/data", requestData);
    return response.data;
  }
);

export const updateDataLogin = createAsyncThunk(
  "api/updateDataLogin",
  async ({ id, updatedData }) => {
    const response = await axios.put(`/api/data/${id}`, updatedData);
    return response.data;
  }
);

export const deleteDataLogin = createAsyncThunk("api/deleteDataLogin", async (id) => {
  await axios.delete(`/api/data/${id}`);
  return id;
});

const apiLoginSlice = createSlice({
  name: "api",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createDataLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDataLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createDataLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDataLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDataLogin.fulfilled, (state, action) => {
        const updatedData = action.payload;
        const index = state.data.findIndex(
          (item) => item.id === updatedData.id
        );
        if (index !== -1) {
          state.data[index] = updatedData;
        }
        state.loading = false;
      })
      .addCase(updateDataLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDataLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDataLogin.fulfilled, (state, action) => {
        const id = action.payload;
        state.data = state.data.filter((item) => item.id !== id);
        state.loading = false;
      })
      .addCase(deleteDataLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default apiLoginSlice.reducer;
