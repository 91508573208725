import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSearchQuery } from "../../store/slices/tableSlice";
import { SearchBar, Pagination } from "../../components";
import { closeModal } from "../../store/slices/deleteModalSlice";
import { fetchDataDrivingSchools, fetchDataDrivingSchools2, createDataDrivingSchools, selectDriveSchool } from "../../store/slices/apiDrivingSchoolsSlice";
import TableItemsDriving from "../TableItemsDriving/TableItemsDriving";
import AddModalDrivingSchools from "../Modals/AddModalDrivingSchools";

const TableDriving = () => {
  const dispatch = useDispatch();
  const dataDiverSchool = useSelector(selectDriveSchool)
  const { currentPage, itemsPerPage, searchQuery } = useSelector(
    (state) => state.table
  );
  const loadingCreate = useSelector((state) => state.apiDrivingSchools.loadingCreate)
  const loadingUpdate = useSelector((state) => state.apiDrivingSchools.loadingUpdate)
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);


  const filteredData = dataDiverSchool.filter(item => {
    const name = item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())
    const city = item.city && item.city.toLowerCase().includes(searchQuery.toLowerCase())
    return name || city
  })


  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = filteredData.slice(firstIndex, lastIndex);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };


  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };

  useEffect(() => {
    dispatch(fetchDataDrivingSchools())
  }, [loadingCreate, loadingUpdate]);
  return (
    <div className="p-3">
      <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
        <SearchBar
          searchInput={searchInput}
          handleSearchInputChange={handleSearchInputChange}
          handleSearchSubmit={handleSearchSubmit}
        />
        <div>
          
            <button className="text-blue-500 mx-2" alt="you can edit here">
              <AddModalDrivingSchools
                title="Shto nje autoshkolle"
                drivingSchoolName="Shto një autoshkollë"
                cityName="Qyteti"
                schoolStatus="Statusi"
                schoolStatus2="Pare"
                schoolNumber="Numri i telefonit"
                emailSchoolName="Email "
                passwordSchoolName="Fjalëkalimi"
                submitButtonText="Shto autoshkollën"
                onCancel={handleCloseModal}
              />
            </button>
        </div>
      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Emri i Autoshkollës
              </th>
              <th scope="col" className="px-6 py-3">
                Qyteti
              </th>
              <th scope="col" className="px-6 py-3">
                Statusi
              </th>
              <th scope="col" className="px-6 py-3">
                Me para ne dore
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Data
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <TableItemsDriving dataDrivingSchools={currentItems}  />
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          filteredData={filteredData}
        />
      </div>
    </div>
  );
};

export default TableDriving;
