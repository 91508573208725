export function calculateDaysForMonth(monthNumber) {
    if (monthNumber < 1 || monthNumber > 12) {
        return 'Invalid month number';
    }
    const fixedDaysInMonth = 30;
    const cumulativeDays = fixedDaysInMonth * monthNumber;
    return cumulativeDays;
}


export function getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function getFutureDateTime(variableDays) {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
  
    futureDate.setMonth(currentDate.getMonth() + variableDays);

    const year = futureDate.getFullYear();
    const month = (futureDate.getMonth() + 1).toString().padStart(2, '0');
    const day = futureDate.getDate().toString().padStart(2, '0');
    const hours = futureDate.getHours().toString().padStart(2, '0');
    const minutes = futureDate.getMinutes().toString().padStart(2, '0');
    const seconds = futureDate.getSeconds().toString().padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  

  export function getFutureDateTimeDay(variableDays) {
    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + variableDays * 24 * 60 * 60 * 1000);
  
    const year = futureDate.getFullYear();
    const month = (futureDate.getMonth() + 1).toString().padStart(2, '0');
    const day = futureDate.getDate().toString().padStart(2, '0');
    const hours = futureDate.getHours().toString().padStart(2, '0');
    const minutes = futureDate.getMinutes().toString().padStart(2, '0');
    const seconds = futureDate.getSeconds().toString().padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  export   function formatDateString(dateString) {
    const dateObject = new Date(dateString);

    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const seconds = dateObject.getSeconds();

    // Format the components as needed
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
}

export function isUserActive(activeStart, activeEnd) {
    const currentDate = new Date();
    const startDate = new Date(activeStart);
    const endDate = new Date(activeEnd);
  
    return startDate <= currentDate && currentDate <= endDate;
  }