import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveMenu } from "../../store/slices/appSlice";
import { AiOutlineMenu } from "react-icons/ai";
import { FaCircleUser } from "react-icons/fa6";
import { logoutUser } from "../../store/slices/authSlice";
import { useNavigate } from "react-router-dom";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <button
    type="button"
    onClick={customFunc}
    style={{ color }}
    className="relative text-xl rounded-full p-3 hover:bg-light-gray"
  >
    <span
      style={{ background: dotColor }}
      className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
    />
    {icon}
  </button>
);

const UserDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = () => {
      dispatch(logoutUser());
      navigate("/login"); 
  };

  const handleProfile = () => {
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  return (
    <div className="relative">
      <button onClick={toggleDropdown} className="flex items-center text-black">
        <FaCircleUser style={{ width: 24, height: 24 }} />
      </button>
      {isDropdownOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg">
          <ul className="py-2">
            <li
              onClick={handleProfile}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
            >
              Profile
            </li>
            <li
              onClick={handleLogout}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
            >
              Logout
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

const Navbar = () => {
  const activeMenu = useSelector((state) => state.app.activeMenu);
  const dispatch = useDispatch();

  const handleCloseSideBar = () => {
    dispatch(setActiveMenu(!activeMenu));
  };

  return (
    <div className="flex justify-between p-2 md:mx-6 relative">
      <NavButton
        title="Menu"
        customFunc={handleCloseSideBar}
        color="black"
        icon={<AiOutlineMenu />}
      />

      <div className="flex">
        <NavButton
          title="User"
          color="black"
          icon={<UserDropdown style={{ width: 24, height: 24 }} />}
        />
      </div>
    </div>
  );
};

export default Navbar;
