import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../store/slices/addModalSlice";
import { FaRegCircleXmark } from "react-icons/fa6";
import { toast } from "react-toastify";
import { createDataDrivingSchools } from "../../store/slices/apiDrivingSchoolsSlice";
import Select from "react-select";
import { dataCity } from "../../data/dataTable";
import { BiShow } from "react-icons/bi";

const AddModalDrivingSchools = ({
  title,
  drivingSchoolName,
  cityName,
  submitButtonText,
  schoolStatus,
  schoolStatus2,
  emailSchoolName,
  passwordSchoolName,
  schoolNumber
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.addModal.isOpen);
  const [drivingSchool, setDrivingSchool] = useState("");
  const [emailSchool, setEmailSchool] = useState("");
  const [passwordSchool, setPasswordSchool] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [schoolIsActive, setSchoolIsActive] = useState(false);
  const [schoolIsActive2, setSchoolIsActive2] = useState(false); 
  const timestamp = new Date().getTime(); 
  const [selectedCity, setSelectedCity] = useState(""); 
  const [showPassword, setShowPassword] = useState(false);


  const handleSchoolStatusChange = (isChecked) => {
    setSchoolIsActive(isChecked);
  };
  const handleSchoolStatusChange2 = (isChecked) => {
    setSchoolIsActive2(isChecked);
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption.value);
  };

  const handleOpenModal = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const data = {
      name: drivingSchool,
      city: selectedCity,
      status: schoolIsActive ? 1 : 0,
      cash: schoolIsActive2 ? 1 : 0,
      phoneNumber: phoneNumber,
      userEmail: emailSchool,
      password: passwordSchool,
      created_at: timestamp,
    };
    
    try {
      await dispatch(createDataDrivingSchools(data));
  
      setDrivingSchool("");
      setCity("");
      setSchoolIsActive(false);
      setSchoolIsActive2(false);
      setPhoneNumber("");
      setEmailSchool("");
      setPasswordSchool("");
      handleCloseModal();
      toast.success("Autoshkolla u shtua me sukses!");
    } catch (error) {
      console.log("Error submitting data:", error);
    }
  };
  

  return (
    <div>
      <button
        data-modal-target="popup-modal"
        data-modal-toggle="popup-modal"
        onClick={handleOpenModal}
        className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-2.5 py-2   hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
      >
        Shto Autoshkollen
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
          <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
          <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "420px" }}
          >
            <button
              type="button"
              onClick={handleCloseModal}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
            >
              <FaRegCircleXmark style={{ width: 20, height: 20 }} />
            </button>

            <div className="p-6 text-center">
              <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                {title}
              </h3>
              <form className="space-y-6 text-start" onSubmit={handleSubmit}>
                {drivingSchoolName && (
                  <div>
                    <label
                      htmlFor="drivingSchool"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {drivingSchoolName}:
                    </label>
                    <input
                      type="text"
                      name="drivingSchool"
                      id="drivingSchool"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Shkruaj emrin... "
                      value={drivingSchool}
                      onChange={(e) => setDrivingSchool(e.target.value)}
                      required
                    />
                  </div>
                )}

                {cityName && (
                  <div>
                    <label
                      htmlFor="questionScore"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {cityName}:
                    </label>
                    <Select
                      id="city"
                      name="city"
                      options={dataCity}
                      value={{ value: selectedCity, label: selectedCity }}
                      onChange={handleCityChange}
                    />
                  </div>
                )}

                {schoolNumber && (
                  <div>
                    <label
                      htmlFor="drivingSchool"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {schoolNumber}:
                    </label>
                    <input
                      type="text"
                      name="drivingSchool"
                      id="drivingSchool"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="+383xxxxxx..."
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                    />
                  </div>
                )}

                {schoolStatus && (
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Statusi Autoshkolles:
                    </label>
                    <div className="flex">
                      <button
                        type="button"
                        className={`mr-2 text-sm rounded-lg px-3 py-1 ${
                          schoolIsActive
                            ? "bg-green-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handleSchoolStatusChange(true)}
                      >
                        Aktiv
                      </button>
                      <button
                        type="button"
                        className={`text-sm rounded-lg px-3 py-1 ${
                          !schoolIsActive
                            ? "bg-red-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handleSchoolStatusChange(false)}
                      >
                        Pasiv
                      </button>
                    </div>
                  </div>
                )}

                {schoolStatus2 && (
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Me para ne dore:
                    </label>
                    <div className="flex">
                      <button
                        type="button"
                        className={`mr-2 text-sm rounded-lg px-3 py-1 ${
                          schoolIsActive2
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handleSchoolStatusChange2(true)}
                      >
                        Po
                      </button>
                      <button
                        type="button"
                        className={`text-sm rounded-lg px-3 py-1 ${
                          !schoolIsActive2
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handleSchoolStatusChange2(false)}
                      >
                        Jo
                      </button>
                    </div>
                  </div>
                )}

                {emailSchoolName && (
                  <div>
                    <label
                      htmlFor="emailSchool"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {emailSchoolName}:
                    </label>
                    <input
                      type="text"
                      name="emailSchool"
                      id="emailSchool"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Shkruaj email... "
                      value={emailSchool}
                      onChange={(e) => setEmailSchool(e.target.value)}
                      required
                    />
                  </div>
                )}

                {passwordSchoolName && (
                  <div>
                    <label
                      htmlFor="passwordSchool"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {passwordSchoolName}:
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="passwordSchool"
                      id="passwordSchool"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Shkruaj password... "
                      value={passwordSchool}
                      onChange={(e) => setPasswordSchool(e.target.value)}
                      required
                    />
                    
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="mt-2 flex items-center text-sm text-gray-700 cursor-pointer hover:text-gray-900 dark:text-gray-400"
                    >  
                     <div className="mr-1">
                     <BiShow/>
                      </div> 
                      <div className="">
                      {showPassword ? "Mos e shih fjalëkalimin " : "Shih fjalëkalimin"}
                        </div>
                    </button>
                  </div>
                )}

                <div className="flex justify-center">
                  <div className="flex items-center">
                    <button
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      {submitButtonText}
                    </button>

                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={handleCloseModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Anulo
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddModalDrivingSchools;
