import React, { useEffect } from 'react'
import { TableFinancialBalance } from '../../components';
import { getAllDebt, selectAllPayment } from '../../store/slices/debtSlice';
import { useDispatch, useSelector } from 'react-redux';
const FinancialBalance = () => {
  const dispatch = useDispatch();
  const paymentData = useSelector(selectAllPayment)
  const allDebt = useSelector((state) =>  state.debt.allDebt)
  const payDebtStatus = useSelector((state) => state.debt.payDebtStatus)
  const loadingReset = useSelector((state) => state.debt.loadingResetPay)
  const totalPaymentAdmin = paymentData?.filter(item => item.isActive === 1).reduce((total, payment) => {
    return total + payment.amount
  }, 0)

  const totalProfit = paymentData?.filter(item => item.isActive === 1).reduce((total, profit) => {
    return total + profit.schoolPrice
  }, 0)

  const payment = allDebt?.reduce((total, debt) => {
    return total + debt.amount
  }, 0)
  
  const totalProfitAdmin = paymentData?.filter(item => item.isActive === 1).reduce((total, profit) => {
    return total + profit.adminPrice
  }, 0)


  const paymentBank = paymentData?.filter((item) => item.cash === 0).reduce((total, payment) => {
    return total + payment.amount
  }, 0)
  
  useEffect(() => {
    dispatch(getAllDebt())
  }, [payDebtStatus, loadingReset])


  return (
    <div>
      <div className='flex justify-around mt-[100px]'>
        <div className='flex flex-col md:flex-row w-[90%] md:w-[100%] justify-center md:space-x-2'>
          <div className='py-5 px-8 rounded-[15px] bg-[#facc15] mt-5'>
            <p className='text-white'>Qarkullimi total  <span className='text-black font-bold'>( {totalPaymentAdmin} €)</span></p>
          </div>
          <div className='py-5 px-8 rounded-[15px] bg-red-500 mt-5'>
            <p className='text-white'>Fitimi total i autoshkollave  <span className='text-black font-bold'>( {totalProfit} €)</span></p>
          </div>
          <div className='py-5 px-8 rounded-[15px] bg-green-500 mt-5'>
            <p className='text-white'>Pagesat <span className='text-black font-bold'> {payment} €</span></p>
          </div>
          <div className='py-5 px-8 rounded-[15px] bg-green-500 mt-5'>
            <p className='text-white'>Pagesat e bankes<span className='text-black font-bold'>( {paymentBank} €)</span></p>
          </div>
        </div>
      </div>
      <div className="m-2 md:m-10 p-2 md:p-6 bg-white rounded-3xl">

        <p className="text-xl mb-4 text-left"> Barazimet e Autoshkollave</p>
        <TableFinancialBalance />
      </div>
    </div>
  );
}

export default FinancialBalance