import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "../../api/axiosInstance";


export const fetchDataPackage = createAsyncThunk("api/fetchDataPackage", async () => {
  try {
    const response = await axiosInstace.get("/pako/getPako"); 
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const createDataPackage = createAsyncThunk(
  "api/createDataPackage",
  async (createDataPackage) => {
    try {
      const response = await axiosInstace.post("/pako/addPako", createDataPackage);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const updateDataPackage = createAsyncThunk(
  "api/updateDataPackage",
  async ({ id, updatedData }) => {
    try {
      const response = await axiosInstace.put(`/pako/updatePako/${id}`, updatedData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteDataPackage = createAsyncThunk(
  "apiQuestion/deleteDataPackage",
  async (itemId, thunkAPI) => {
    try {
      await axiosInstace.delete(`/pako/deletePako/${itemId}`);
      return itemId;
    } catch (error) {
      throw new Error(error.response.data.message);

    }
  }
);

const apiPackageSlice = createSlice({
  name: "apiPackage",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataPackage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataPackage.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataPackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createDataPackage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDataPackage.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createDataPackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDataPackage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDataPackage.fulfilled, (state, action) => {
        const updatedData = action.payload;
        const index = state.data.findIndex((item) => item.id === updatedData.id);
        if (index !== -1) {
          state.data[index] = updatedData;
        }
        state.loading = false;
      })
      .addCase(updateDataPackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDataPackage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDataPackage.fulfilled, (state, action) => {
        const deletedItemId = action.payload;
        state.data = state.data.filter((item) => item.id !== deletedItemId);
        state.loading = false;
      })
      .addCase(deleteDataPackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default apiPackageSlice.reducer;