import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "../../api/axiosInstance";


export const fetchDataFinancialBalance = createAsyncThunk("api/fetchDataFinancialBalance", async () => {
  try {
    const response = await axiosInstace.get("/payment/payments"); 
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const createDataFinancialBalance = createAsyncThunk(
  "api/createDataFinancialBalance",
  async (createDataFinancialBalance) => {
    try {
      const response = await axiosInstace.post("/payment/createPayment", createDataFinancialBalance);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const createDataDebt = createAsyncThunk(
  "api/createDataDebt",
  async ({ id, updatedData }) => {
    try {
      const response = await axiosInstace.post(`/debt/createDebt`, updatedData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const updateDataFinancialBalance = createAsyncThunk(
  "api/updateFinancialBalance",
  async ({ id, updatedData }) => {
    try {
      const response = await axiosInstace.put(`/payment/updatePayment/${id}`, updatedData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteDataFinancialBalance = createAsyncThunk(
  "apiQuestion/deleteDataFinancialBalance",
  async (itemId, thunkAPI) => {
    try {
      await axiosInstace.delete(`/payment/deletePayment/${itemId}`);
      return itemId;
    } catch (error) {
      throw new Error(error.response.data.message);

    }
  }
);

export const fetchDebtById = createAsyncThunk(
  "apiFinancialBalance/fetchDebtById",
  async (id) => {
    try {
      const response = await axiosInstace.get(`/debt/getDebtHistory/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const apiFinancialBalanceSlice = createSlice({
  name: "apiFinancialBalance",
  initialState: {
    data: [],
    loading: false,
    error: null,
    debt:[]
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataFinancialBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataFinancialBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataFinancialBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createDataFinancialBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDataFinancialBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createDataFinancialBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDataFinancialBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDataFinancialBalance.fulfilled, (state, action) => {
        const updatedData = action.payload;
        const index = state.data.findIndex((item) => item.id === updatedData.id);
        if (index !== -1) {
          state.data[index] = updatedData;
        }
        state.loading = false;
      })
      .addCase(updateDataFinancialBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createDataDebt.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDataDebt.fulfilled, (state, action) => {
        const updatedData = action.payload;
        const index = state.data.findIndex((item) => item.id === updatedData.id);
        if (index !== -1) {
          state.data[index] = updatedData;
        }
        state.loading = false;
      })
      .addCase(createDataDebt.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDataFinancialBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDataFinancialBalance.fulfilled, (state, action) => {
        const deletedItemId = action.payload;
        state.data = state.data.filter((item) => item.id !== deletedItemId);
        state.loading = false;
      })
      .addCase(deleteDataFinancialBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDebtById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDebtById.fulfilled, (state, action) => {
        state.loading = false;
        state.debt = action.payload;
      })
      .addCase(fetchDebtById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default apiFinancialBalanceSlice.reducer;