import React from "react";
import { useDispatch, useSelector } from "react-redux";
import EditModalLiterature from "../Modals/EditModal/EditModalLiterature";
import InfoModal from "../Modals/InfoModal/InfoModal";
import { openModalDelete } from "../../store/slices/deleteModalLiteratureSlice";
import DeleteModalLiterature from "../Modals/DeleteModal/DeleteModalLiterature";
import { openModal, closeModal } from "../../store/slices/editModalSlice";


const TableItemsLiterature = ({ dataLiterature, baseUrl }) => {
  const dispatch = useDispatch();
  const editModalState = useSelector((state) => state.editModalLiterature);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  const handleEditItem = (id) => {
    dispatch(openModal(id));
  };
  const handleDeleteItem = (id) => {
    dispatch(openModalDelete(id));
  };

  function getYesNoTextFromBoolean(value) {
    return value ? "Po" : "Jo";
  }
  console.log(dataLiterature)
  return (
    <>
      {dataLiterature?.map((item) => (
        <tr
          key={item.id}
          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {item.name}
          </td>
          <td className="px-6 py-4">{item.categoryName}</td>
          <td className="px-6 py-4">{getYesNoTextFromBoolean(item.isPremium)}</td>
          <td className="px-6 py-4">
            <a href={`${baseUrl}/${item.icon}`} target="_blank" rel="noopener noreferrer">
              <img src={`${baseUrl}/${item.icon}`}
                alt="Question"
                className="w-14 h-14 object-cover"
              />
            </a>
          </td>
          <td className="px-6 py-4">
            {item.description}
          </td>
          <td className="px-6 py-4 w-40">
            <button className="text-blue-500 mr-2">
              <InfoModal
                title="Info rreth kategorise"
                onCancel={handleCloseModal}
                inputLabel1="Lloji i kategorisë"
                inputLabel2="Emri 1"
                inputLabel4="Emri 2"
              />
            </button>
            <button className="text-sucess mx-1" onClick={() => handleEditItem(item.id)}>
              <EditModalLiterature
                id={item.id}
                onCancel={handleCloseModal}
                title="Edito një literaturë"
                submitButtonText="Edito literaturën"
                questionsName="Emri literaturës"
                CategoryName="Kategorisa e literaturës"
                premiumStatus = "A eshte premium"
                isOpen={editModalState[item.id] === true}
              />
            </button>
            <button className="text-danger mx-1"  onClick={() => handleDeleteItem(item.id)}>
              <DeleteModalLiterature
              id={item.id} 
              onCancel={handleCloseModal}
              />
            </button>
          </td>
        </tr>
      ))}
    </>
  );
};

export default TableItemsLiterature;
