import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSearchQuery } from "../../store/slices/tableSlice";
import {
  SearchBar,
  Pagination,
  TableItemsQuestionsCategory
} from "../../components";
import { closeModal } from "../../store/slices/deleteModalSlice";
import {
  deleteDataQuestion
} from "../../store/slices/apiQuestionSlice";
import "react-toastify/dist/ReactToastify.css";
import AddModalQuestionCategory from "../Modals/AddModalQuestionCategory";

import { fetchDataQuestionCategory } from "../../store/slices/apiQuestionCategorySlice";


const TableQuestionsCategory = () => {
  const dispatch = useDispatch();
  const loadinUpdate = useSelector((state) => state.apiQuestionCategory.loadingUpdate)
  const loading = useSelector((state) => state.apiQuestionCategory.loadinCreate)
  const { currentPage, itemsPerPage, searchQuery } = useSelector(
    (state) => state.table
  );
  const backendBaseUrl = 'https://back.autoshkollaime.com';
  const dataQuestionCategory = useSelector((state) => state.apiQuestionCategory.data);
  const [searchInput, setSearchInput] = useState("");

  const filteredData = dataQuestionCategory.filter((item) => {
    const name = item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())
    const categoryName = item.categoryName && item.categoryName.toLowerCase().includes(searchQuery.toLowerCase())
    return name || categoryName;
  });

 

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = filteredData.slice(firstIndex, lastIndex);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };



  useEffect(() => {
    dispatch(fetchDataQuestionCategory());
  }, [loadinUpdate, loading]);

  return (
    <div className="p-3">
      <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
        <SearchBar
          searchInput={searchInput}
          handleSearchInputChange={handleSearchInputChange}
          handleSearchSubmit={handleSearchSubmit}
        />
        <div>
          <button className="text-danger mx-2" alt="you can edit here">
            <AddModalQuestionCategory
              title="Shto një pyetje me kategori"
              submitButtonText="Shto pyetjen"
              onCancel={handleCloseModal}
              nameQuestionCategory="Emri i Pyetjes me Kategori"
              categoryQuestionCategory="Zgjedh kategorinë e pyetjes"
              photoQuestionCategory="Shto ikonën"
              premiumStatus="Premium"
            />
          </button>
        </div>
      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Emri i Pyetjes
              </th>
              <th scope="col" className="px-6 py-3">
                Kategoria
              </th>
              <th scope="col" className="px-6 py-3">
                Ikona
              </th>
              <th scope="col" className="px-6 py-3">
                Premium
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <TableItemsQuestionsCategory
              dataQuestionCategory={currentItems}
              baseUrl={backendBaseUrl}
            />
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          filteredData={filteredData}
        />
      </div>
    </div>
  );
};

export default TableQuestionsCategory;