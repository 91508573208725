import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const deleteModalLiteratureSlice = createSlice({
  name: "deleteModalLiterature",
  initialState,
  reducers: {
    openModalDelete: (state, action) => {
      const itemId = action.payload;
      state[itemId] = true;
    },
    closeModalDelete: (state, action) => {
      const itemId = action.payload;
      state[itemId] = false;
    },
  },
});

export const { openModalDelete, closeModalDelete } = deleteModalLiteratureSlice.actions;
export default deleteModalLiteratureSlice.reducer;