import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  updateSearchQuery } from "../../store/slices/tableSlice";
import { SearchBar, Pagination, TableItemsCategory } from "../../components";
import AddModal from "../Modals/AddModal";
import { closeModal } from "../../store/slices/deleteModalSlice";
import { fetchDataCategories,createDataCategories } from "../../store/slices/apiCategorySlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TableCategories = () => {
  const dispatch = useDispatch();

  const { currentPage, itemsPerPage, searchQuery } = useSelector(
    (state) => state.table
  );
  const dataCategories = useSelector((state) => state.apiCategory.data);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    dispatch(fetchDataCategories());
  }, [dispatch]);

  const filteredData = dataCategories.filter((item) =>
  item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())
);


  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = filteredData.slice(firstIndex, lastIndex);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };

  const handleAddCategory = (data) => {
    dispatch(createDataCategories(data));
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <div className="p-3">
      <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
        <SearchBar
          searchInput={searchInput}
          handleSearchInputChange={handleSearchInputChange}
          handleSearchSubmit={handleSearchSubmit}
        />
        <div>
          <button className="text-blue-500 mx-2" alt="you can edit here">
            <AddModal
              title="Shto një kategori"
              submitButtonText="Shto kategorine"
              onCancel={handleCloseModal}
              onSubmit={handleAddCategory}
              inputLabel1="Lloji i kategorise"
              inputLabel2="Emri 1"
              inputLabel4="Emri 2"
              premiumStatus="A eshte premium"
            />
          </button>
        </div>
      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Lloji i Kategorisë
              </th>
              <th scope="col" className="px-6 py-3">
                Emri 1
              </th>
              <th scope="col" className="px-6 py-3">
                Emri 2
              </th>
              <th scope="col" className="px-6 py-3">
                Premium
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
          <TableItemsCategory
            dataCategories={currentItems}
            showCity={false}
            showCategory={false}
          />
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          filteredData={filteredData}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default TableCategories;
