import React from 'react';
import { TableUsers } from '../../components';

const Users = () => {
  
  return (
    <div>
      <div className="m-2 md:m-10 p-2 md:p-6 bg-white rounded-3xl">
        <p className="text-xl mb-4 text-left">Kandidatët</p>
        <TableUsers />
      </div>
    </div>
  );
};

export default Users;
