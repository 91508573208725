import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSearchQuery } from "../../store/slices/tableSlice";
import { SearchBar, Pagination, TableItemsUsers } from "../../components";
import { closeModal } from "../../store/slices/deleteModalSlice";
import { fetchDataUsers, selectAllUserData } from "../../store/slices/authSlice";

const TableUsers = () => {
  const dispatch = useDispatch();
  const dataUser = useSelector(selectAllUserData)
  const { currentPage, itemsPerPage, searchQuery } = useSelector(
    (state) => state.table
  );

  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    dispatch(fetchDataUsers())
  }, []);


  const filteredData = dataUser.filter((item) => {
    const name = item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())
    const driveSchoolName = item.driveSchoolName && item.driveSchoolName.toLowerCase().includes(searchQuery.toLowerCase())
    const email = item.email && item.email.toLowerCase().includes(searchQuery.toLowerCase());
    const deviceId = item.deviceId && item.deviceId.toLowerCase().includes(searchQuery.toLowerCase());

    
    return driveSchoolName || email || deviceId || name
  })

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = filteredData.slice(firstIndex, lastIndex);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };

  return (
    <div className="p-3">
      <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
        <SearchBar
          searchInput={searchInput}
          handleSearchInputChange={handleSearchInputChange}
          handleSearchSubmit={handleSearchSubmit}
        />
        <div></div>
      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Emri dhe Mbiemri
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Numri Telefonit
              </th>
              <th scope="col" className="px-6 py-3">
                Autoshkolla
              </th>
              <th scope="col" className="px-6 py-3">
                Device id
              </th>
              <th scope="col" className="px-6 py-3">
                Abonimi
              </th>  <th scope="col" className="px-6 py-3">
                Kohëzgjatja
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <TableItemsUsers dataUsers={currentItems} />
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          filteredData={filteredData}
        />
      </div>
    </div>
  );
};

export default TableUsers;