import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../../store/slices/editModalCategorySlice";
import { FaPencilAlt } from "react-icons/fa";
import { FaRegCircleXmark } from "react-icons/fa6";
import Select from "react-select";
import {fetchDataExams, updateDataExams} from "../../../store/slices/apiExamsSlice"
import { fetchDataCategories, updateDataCategories } from "../../../store/slices/apiCategorySlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditModalCategory = ({
  id,
  title,
  submitButtonText,
  categoryName,
  subtitleName,
  subtitleName2,
  premiumStatus
 
}) => {
  const dispatch = useDispatch();
  const dataExams = useSelector((state) => state.apiCategory.data);
  const isOpen = useSelector((state) => state.editModalCategory[id] === true);


  const [changeCategoryName, setChangeCategoryName] = useState("");
  const [changeSubtitle1, setChangeSubtitle1] = useState("");
  const [changeSubtitle2, setChangeSubtitle2] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [showId, setShowId] = useState("");
  const [premiumCategory, setPremiumCategory] = useState(false);



  useEffect(() => {
    dispatch(fetchDataExams());
    dispatch(fetchDataCategories()); 
  }, []);

  const handlePremiumCategory = (isChecked) => {
    setPremiumCategory(isChecked);
  };
 
  const handleOpenModal = () => {
    const itemToEdit = dataExams.find((item) => item.id === id);

    if (itemToEdit) {
      setChangeCategoryName(itemToEdit.name);
      setChangeSubtitle1(itemToEdit.subtitle1);
      setChangeSubtitle2(itemToEdit.subtitle2);
      setPremiumCategory(itemToEdit.isPremium === 1); 
    }
    dispatch(openModal(id));
  };


  const handleCloseModal = () => {
    dispatch(closeModal(id));
  };

  const handleOptionChange = (selectedOptions) => {
    setSelectedOption(selectedOptions);
  };

  const handleEditItem = () => {
    const selectedCategoryId = selectedOption ? selectedOption.value : null; 

    const updatedData = {
      name: changeCategoryName,
      subtitle1: changeSubtitle1,
      subtitle2: changeSubtitle2,
      isPremium: premiumCategory ? 1 : 0

    };

    dispatch(updateDataCategories({ id: id, updatedData }));
    toast.success("Te dhenat jan ruajtur me sukses!");
    dispatch(fetchDataCategories())
    handleCloseModal();
  };

  return (
    <div>
      <button
        data-modal-target="popup-modal"
        data-modal-toggle="popup-modal"
        onClick={handleOpenModal}
      >
        <FaPencilAlt style={{ width: 20, height: 20 }} />
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
          <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
          <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "360px" }}
          >
            <button
              type="button"
              onClick={handleCloseModal}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
            >
              <FaRegCircleXmark style={{ width: 20, height: 20 }} />
            </button>

            <div className="p-6 text-center">
              <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                {title}
              </h3>
              <form className="space-y-6 text-start" onSubmit={handleEditItem}>
                <div>
                  <label
                    htmlFor="examsName"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {categoryName}:
                  </label>
                  <input
                    type="text"
                    name="examsName"
                    id="examsName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder=""
                    value={changeCategoryName}
                    onChange={(e) => setChangeCategoryName(e.target.value)}
                  />
                </div>

                  <div>
                    <label
                      htmlFor="subtitleName"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {subtitleName}:
                    </label>
                    <textarea
                      name="examsName"
                      id="examsName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white resize-y"
                      placeholder=""
                      value={changeSubtitle1}
                      onChange={(e) => setChangeSubtitle1(e.target.value)}
                      rows="4" 
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="subtitleName"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {subtitleName2}:
                    </label>
                    <textarea
                      name="examsName"
                      id="examsName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white resize-y"
                      placeholder=""
                      value={changeSubtitle2}
                      onChange={(e) => setChangeSubtitle2(e.target.value)}
                      rows="4" 
                    />
                  </div>

                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Premium: 
                    </label>
                    <div className="flex">
                      <button
                        type="button"
                        className={`mr-2 text-sm rounded-lg px-3 py-1 ${
                          premiumCategory
                            ? "bg-green-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handlePremiumCategory(true)}
                      >
                        Po
                      </button>
                      <button
                        type="button"
                        className={`text-sm rounded-lg px-3 py-1 ${
                          !premiumCategory
                            ? "bg-red-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handlePremiumCategory(false)}
                      >
                        Jo
                      </button>
                    </div>
                  </div>

                <div className="flex justify-center">
                  <div className="flex items-center">
                    <button
                      data-modal-hide="popup-modal"
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      {submitButtonText}
                    </button>

                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={handleCloseModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Anulo
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditModalCategory;