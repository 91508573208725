import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const fetchDataQuestions = createAsyncThunk("api/fetchDataQuestions", async () => {
  try {
    const response = await axiosInstance.get("/question");
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const updateDataQuestions = createAsyncThunk(
  "api/updateQuestions",
  async ({id, updateData}) => {
    try {
      const response = await axiosInstance.put(`/question/updateQuestion/${id}`, updateData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);





export const createQuestionCategoriWithAnswer =
 createAsyncThunk('add/createQuestionCategoryWithAnswer',
  async (createDataQuestionWithAnswer) => {
   try {
    const response = await axiosInstance.post('/questionCategory/addQuestionAndAnswer', createDataQuestionWithAnswer, {
      headers: { 'Content-Type': 'multipart/form-data'}
    });
    return response.data;
   } catch (error) {
    throw new Error(error.response.data.message);
   }
})


export const createDataQuestions = createAsyncThunk(
  "api/createDataQuestions",
  async (createDataQuestions) => {
    try {
      const response = await axiosInstance.post("/question/addQuestionWithAnswers", createDataQuestions);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteDataQuestion = createAsyncThunk(
  "api/deleteQuestion",
   async (itemId, thunkAPI) => {
    try {
      await axiosInstance.delete(`/question/deleteQuestion/${itemId}`);
      return itemId;
    } catch (error) {
      throw new Error(error.response.data.message);

    }
  }
);


export const fetchUserByid = createAsyncThunk("userById/fetchUserById", async (id) => {
  try {
    const response = await axiosInstance.get(`/question/getQuestionById/${id}`);
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message);
  }
})

export const editAnswerQuestion = createAsyncThunk('editAnswerQuestion', async (data) => {
  try {
    const response = await axiosInstance.post(`/question/editAnswerQuestion`, data);
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message);
  }
})

const apiQuestionSlice = createSlice({
  name: "apiQuestion",
  initialState: {
    data: [],
    loading: false,
    error: null,
    userDataById: [],
    errorDataById: null,
    loadingDataById: false,
    loadingUpdate: false,
    loadingCreate: false,
    loadingCreateQuestion: false,
    editAnswerQuestion: false,
    loadingUpdateAnswer: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(editAnswerQuestion.fulfilled, (state) => {
      state.loadingUpdateAnswer = !state.loadingUpdateAnswer
    })
    .addCase(createQuestionCategoriWithAnswer.fulfilled, (state) => {
        state.loadingCreate = !state.loadingCreate
    })
    .addCase(fetchDataQuestions.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchDataQuestions.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    })
    .addCase(fetchDataQuestions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
       .addCase(createDataQuestions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDataQuestions.fulfilled, (state, action) => {
        state.loadingCreateQuestion = !state.loadingCreateQuestion;
      })
      .addCase(createDataQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDataQuestions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDataQuestions.fulfilled, (state, action) => {
        state.loadingUpdate = !state.loadingUpdate;
      })
      .addCase(updateDataQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDataQuestion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDataQuestion.fulfilled, (state, action) => {
        const deletedItemId = action.payload;
        state.data = state.data.filter((item) => item.id !== deletedItemId);
        state.loading = false;
      })
      .addCase(deleteDataQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserByid.pending, (state) => {
        state.loadingDataById = true;
        state.errorDataById = null;
      })
      .addCase(fetchUserByid.fulfilled, (state, action) => {
        state.userDataById = action.payload;
        state.loadingDataById = false;
      })
      .addCase(fetchUserByid.rejected, (state, action) => {
        state.loadingDataById = false;
        state.error = action.error.message;
      })
  },
});

export const selectUserById = (state) => state.apiQuestion.userDataById
export default apiQuestionSlice.reducer;