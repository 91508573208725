import React, { useState } from "react";
import { FiLock, FiMail } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../store/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { setAdminRole, setUserRole } from "../../store/slices/userSlice";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from 'jwt-decode';
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleLoginEvent = async (e) => {
    e.preventDefault();
    const credentials = {
      email,
      password,
    };
    dispatch(loginUser(credentials)).unwrap()
      .then(response => {
        if (response.success === false) {
          toast.error("Emaili ose fjalëkalimi janë gabim!");
        } else {

          const decoded = jwt_decode(response.token);
          localStorage.setItem('userToken', response.token);
          localStorage.setItem('isAuthenticated', true);
          if (decoded.role === 'admin') {
            navigate("/dashboard")
          } else {
            navigate('/movment')
          }
          toast.success("Jeni kyçur me sukses!");
        }
      });



  };

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-main-bg">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-black-900">
          Kyçu
        </h1>
        <form
          className="form-group custom-form mt-6"
          onSubmit={handleLoginEvent}
        >
          <div className="mb-2">
            <label className="relative text-gray-400 focus-within:text-gray-600 block">
              <FiMail className="pointer-events-none w-5 h-5 absolute top-1/2 transform -translate-y-1/2 left-3" />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="test@youremail.com"
                className="form-input border rounded-md py-3 px-4 bg-white placeholder-gray-400 text-gray-500 text-sm appearance-none w-full block pl-14 focus:outline-none focus:border-black"
                required
              />
            </label>
          </div>
          <div className="mb-2">
            <label className="relative text-gray-400 focus-within:text-gray-600 block">
              <FiLock className="pointer-events-none w-5 h-5 absolute top-1/2 transform -translate-y-1/2 left-3" />

              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="fjalëkalimi juaj"
                className="form-input border rounded-md py-3 px-4 bg-white placeholder-gray-400 text-gray-500 text-sm appearance-none w-full block pl-14 focus:outline-none focus:border-black"
                required
              />
            </label>
          </div>
          <div className="mt-6">
            <button
              type="submit"
              className="w-full pl-4 pt-3 pb-2.5 tracking-wide text-white text-sm transition-colors duration-200 transform bg-theme-color rounded-md hover:bg-hover-color focus:outline-none focus:bg-hover-color"
              disabled={loading}
            >
              {loading ? "Duke procesuar..." : "Kycu"}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;