import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const deleteModalSubliteratureSlice = createSlice({
  name: "deleteModalSubliterature",
  initialState,
  reducers: {
    openModalDelete: (state, action) => {
      const itemId = action.payload;
      state[itemId] = true;
    },
    closeModalDelete: (state, action) => {
      const itemId = action.payload;
      state[itemId] = false;
    },
  },
});

export const { openModalDelete, closeModalDelete } = deleteModalSubliteratureSlice.actions;
export default deleteModalSubliteratureSlice.reducer;
