import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../../store/slices/editModalUsersSlice";
import { FaPencilAlt } from "react-icons/fa";
import { FaRegCircleXmark } from "react-icons/fa6";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchDataUsers, updateDataUsers } from "../../../store/slices/authSlice";

const EditModalUsers = ({
  id,
  title,
  submitButtonText,
  durationName,
  subscriptionStatus
 
}) => {
  const dispatch = useDispatch();
  const dataExams = useSelector((state) => state.auth.data);
  const isOpen = useSelector((state) => state.editModalUsers[id] === true);

 const [password, setPassword] = useState('')

  useEffect(() => {
    dispatch(fetchDataUsers());
  }, []);

 
 
  const handleOpenModal = () => {
    dispatch(openModal(id));
  };


  const handleCloseModal = () => {
    dispatch(closeModal(id));
  };

const handleEditItem = async (e) => {
  e.preventDefault();
    const updatedData = {
      password
    };

    try {
      dispatch(updateDataUsers({ id: id, updatedData }));
      toast.success("Te dhenat jan ruajtur me sukses!");
      handleCloseModal();

    } catch (error) {
      console.log(error)
    }
  };
  

  return (
    <div>
      <button
        data-modal-target="popup-modal"
        data-modal-toggle="popup-modal"
        onClick={handleOpenModal}
      >
        <FaPencilAlt style={{ width: 20, height: 20 }} />
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
          <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
          <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "360px" }}
          >
            <button
              type="button"
              onClick={handleCloseModal}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
            >
              <FaRegCircleXmark style={{ width: 20, height: 20 }} />
            </button>

            <div className="p-6 text-center">
              <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                {title}
              </h3>
              <form className="space-y-6 text-start" onSubmit={handleEditItem}>
              <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                   Password:
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder=""
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>

              

                <div className="flex justify-center">
                  <div className="flex items-center">
                    <button
                      data-modal-hide="popup-modal"
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                    Ndrysho Passwordin
                    </button>

                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={handleCloseModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Anulo
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditModalUsers;



