import React from 'react'
import { TableDriving } from '../../components';

const DrivingSchool = () => {
  return (
    <div className="m-2 md:m-8 p-2 md:p-6 bg-white rounded-3xl">
      <p className="text-xl mb-4 text-left">Lista e Autoshkollave</p>
      <TableDriving />
    </div>
  );
}

export default DrivingSchool