import React from "react";
import { useDispatch } from "react-redux";
import { updatePage } from "../../store/slices/tableSlice";

const Pagination = ({ currentPage, itemsPerPage, filteredData }) => {
  const dispatch = useDispatch();

  const handlePreviousPage = () => {
    dispatch(updatePage(currentPage - 1));
  };

  const handleNextPage = () => {
    dispatch(updatePage(currentPage + 1));
  };

  return (
    <nav
      className="flex items-center justify-between p-5"
      aria-label="Table navigation"
    >
      <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
        Showing{" "}
        <span className="font-semibold text-gray-900 dark:text-white">
          {Math.min((currentPage - 1) * itemsPerPage + 1, filteredData.length)}-
          {Math.min(currentPage * itemsPerPage, filteredData.length)}
        </span>{" "}
        of{" "}
        <span className="font-semibold text-gray-900 dark:text-white">
          {filteredData.length}
        </span>
      </span>
      <ul className="inline-flex -space-x-1 text-sm h-8">
        <li>
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            Previous
          </button>
        </li>
        <li>
          <button
            onClick={handleNextPage}
            disabled={currentPage * itemsPerPage >= filteredData.length}
            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
