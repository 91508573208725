import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const deleteModalQuestionsCategorySlice = createSlice({
  name: "deleteModalQuestionsCategory",
  initialState,
  reducers: {
    openModalDelete: (state, action) => {
      const itemId = action.payload;
      state[itemId] = true;
    },
    closeModalDelete: (state, action) => {
      const itemId = action.payload;
      state[itemId] = false;
    },
  },
});

export const { openModalDelete, closeModalDelete } = deleteModalQuestionsCategorySlice.actions;
export default deleteModalQuestionsCategorySlice.reducer;