import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const editModalDebtSlice = createSlice({
  name: "editModalDebt",
  initialState,
  reducers: {
    openModalDebt: (state, action) => {
      const itemId = action.payload;
      state[itemId] = true;
    },
    closeModalDebt: (state, action) => {
      const itemId = action.payload;
      state[itemId] = false;
    },
  },
});

export const { openModalDebt, closeModalDebt } = editModalDebtSlice.actions;

export default editModalDebtSlice.reducer;
