import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "../../api/axiosInstance";

export const fetchDataQuestionCategory = createAsyncThunk("api/fetchDataQuestionCategory", async () => {
  try {
    const response = await axiosInstace.get("/questionCategory/getCategoryQuestion");
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});


export const createDataQuestionCategory = createAsyncThunk(
  "api/createDataQuestionCategory",
  async (createDataQuestionCategory) => {
    try {
      const response = await axiosInstace.post("/questionCategory/addQuestionCategory", createDataQuestionCategory);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const updateDataQuestionsWithCategory = createAsyncThunk(
  "api/updateQuestionsWithCategory",
  async ({ id, updatedData }) => {
    try {
      const response = await axiosInstace.post(`/questionCategory/updateQuestionWithCategory/${id}`, updatedData);
      console.log(response.data)
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const updateDataQuestionCategory = createAsyncThunk(
  "api/updateQuestionsCategory",
  async ({ id, updatedData }) => {

    try {
      const response = await axiosInstace.put(`/questionCategory/editQuestionCategory/${id}`, updatedData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteDataQuestionCategory = createAsyncThunk(
  "api/deleteQuestionCategory",
  async (itemId, thunkAPI) => {
    try {
      await axiosInstace.delete(`/questionCategory/deleteQuestionCategory/${itemId}`);
      return itemId;
    } catch (error) {
      throw new Error(error.response.data.message);

    }
  }
);


export const deleteQuestionCategoryWithAnswer = createAsyncThunk(
  "deleteQuestionWithAnswer/deleteAnswer", async (id) => {
    try {
      const response = await axiosInstace.post(`/questionCategory/deleteQuestionWithAnswer/${id}`);
      return response.data
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
)

export const fetchQuestionWithAnswerCategory = createAsyncThunk('api/fetchDataQuestionWithCategory', async () => {
  try {
    const response = await axiosInstace.get('/questionCategory/getQuestionWithAnswerWithCategory');
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
})


export const editAnswerQuestion = createAsyncThunk('editAnswerQuestion', async (data) => {
  try {
    const response = await axiosInstace.post('/questionCategory/editAnswerQuestionCategory', data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
})
const apiQuestionCategorySlice = createSlice({
  name: "apiQuestionCategory",
  initialState: {
    data: [],
    loading: false,
    error: null,
    dataQuestion: [],
    loadingQuestion: false,
    errorLoading: null,
    loadingUpdate: false,
    deleteLoading: false,
    loadingUpdateQuestion: false,
    loadinCreate: false,
    editAnswerQuestion: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(editAnswerQuestion.fulfilled, (state) => {
        state.editAnswerQuestion = !state.editAnswerQuestion
      })
      .addCase(updateDataQuestionsWithCategory.fulfilled, (state) => {
        state.loadingUpdateQuestion = !state.loadingUpdateQuestion
      })
      .addCase(deleteQuestionCategoryWithAnswer.fulfilled, (state) => {
        state.deleteLoading = !state.deleteLoading;
      })
      .addCase(fetchQuestionWithAnswerCategory.pending, (state) => {
        state.loadingQuestion = true;
        state.errorLoading = null
      })
      .addCase(fetchQuestionWithAnswerCategory.fulfilled, (state, action) => {
        state.loadingQuestion = false;
        state.dataQuestion = action.payload;
      })
      .addCase(fetchQuestionWithAnswerCategory.rejected, (state, action) => {
        state.loadingQuestion = true;
        state.errorLoading = null
      })
      .addCase(fetchDataQuestionCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataQuestionCategory.fulfilled, (state, action) => {
        state.loading = !state.loading;
        state.data = action.payload;
      })
      .addCase(fetchDataQuestionCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createDataQuestionCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDataQuestionCategory.fulfilled, (state, action) => {
        state.loadinCreate = !state.loadinCreate;
      })
      .addCase(createDataQuestionCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDataQuestionCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDataQuestionCategory.fulfilled, (state, action) => {
        state.loadingUpdate = !state.loadingUpdate;
      })
      .addCase(updateDataQuestionCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDataQuestionCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDataQuestionCategory.fulfilled, (state, action) => {
        const deletedItemId = action.payload;
        state.data = state.data.filter((item) => item.id !== deletedItemId);
        state.loading = false;
      })
      .addCase(deleteDataQuestionCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectQuestionWithCategory = (state) => state.apiQuestionCategory.dataQuestion
export const selectQuestionCategory = (state) => state.apiQuestionCategory.data
export default apiQuestionCategorySlice.reducer;
