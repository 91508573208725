import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "../../api/axiosInstance";


export const fetchAllPayment = createAsyncThunk('fetchAllPayment', async () => {
    try {
        const response = await axiosInstace.get("/payment/payments");
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})


export const fetchAllActiveCategory = createAsyncThunk('fetchAllActiveCategory', async () => {
    try {
        const response = await axiosInstace.get("/debt/getAllActiveCategory");
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})
const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        data: [],
        loading: false,
        error: null,
        activeCat: [],
        loadingActiveCat: false,
        errorActiveCat: null
    },

    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllActiveCategory.rejected, (state, action) => {
                state.loadingActiveCat = false;
                state.errorActiveCat = action.error.message
            })
            .addCase(fetchAllActiveCategory.pending, (state) => {
                state.loadingActiveCat = true;
                state.errorActiveCat = null;
            })
            .addCase(fetchAllActiveCategory.fulfilled, (state, action) => {
                state.loadingActiveCat = false;
                state.activeCat = action.payload;
            })
            .addCase(fetchAllPayment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchAllPayment.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchAllPayment.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
    }
})

export default paymentSlice.reducer