import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal,closeModal } from "../../store/slices/editModalSlice";
import { closeModalDelete, openModalDelete } from "../../store/slices/deleteModalPackageSlice";
import InfoModal from "../Modals/InfoModal";
import EditModalExams from "../Modals/EditModal/EditModalExams";
import DeleteModalExams from "../Modals/DeleteModal/DeleteModalExams";
import DeleteModalPackage from "../Modals/DeleteModal/DeleteModalPackage";
import EditModalPackage from "../Modals/EditModal/EditModalPackage";

const TableItemsPackage = ({ dataPackage }) => {
  const dispatch = useDispatch();
  const deleteModalState = useSelector((state) => state.deleteModalExams);
  const editModalState = useSelector((state) => state.editModalPackage); 

  const handleCloseModal = (modalType) => {
    if (modalType === "edit") {
      dispatch(closeModal()); 
    } else if (modalType === "delete") {
      dispatch(closeModalDelete()); 
    } else if (modalType === "info") {
      dispatch(closeModalDelete()); 
    }
  };

  const handleOpenModal = (id) => {
    dispatch(openModal(id));
  };

  const handleEditItem = (id) => {
    dispatch(openModal(id));
  };

  const handleDeleteItem = (id) => {
    dispatch(openModalDelete(id));
  };

  return (
    <>
      {dataPackage.map((item) => (
        <tr
          key={item.id}
          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {item.name}
          </td>
          <td className="px-6 py-4">{item.categoryName}</td>
          <td className="px-6 py-4">{item.price}.00 € </td>
          <td className="px-6 py-4">{item.schoolPrice}.00 € </td>          
          <td className="px-6 py-4">{item.adminPrice}.00 € </td>
          <td className="px-6 py-4">{item.month}</td>
          <td className="px-6 py-4 w-40">
            <button className="text-blue-500 mr-2">
              <InfoModal
                title="Info rreth testit"
                name="Emri i testit"
                categoryInInfo="Kategoria e testit"
              />
            </button>
            <button className="text-sucess mx-1" onClick={() => handleEditItem(item.id)}>
              <EditModalPackage
                id={item.id}
                title="Edito një pako"
                submitButtonText="Edito pakon"
                namePackage="Emri i pakosë"
                packageCategory="Lloji i kategorisë"
                priceValue="Cmimi"
                adminValue="Cmimi i adminit"
                schoolValue="Cmimi i autoshkolles"
                isOpen={editModalState[item.id] === true}
              />
            </button>
            <button className="text-danger mx-1" onClick={() => handleDeleteItem(item.id)}>
              <DeleteModalPackage 
                id={item.id} 
              />
            </button>
          </td>
        </tr>
      ))}
    </>
  );
};

export default TableItemsPackage;





