import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateData,updateSearchQuery} from "../../store/slices/tableSlice";
import {SearchBar,Pagination, TableItemsTextLiterature} from '../../components'
import { closeModal } from "../../store/slices/deleteModalSlice";
import { fetchDataTextLiterature } from "../../store/slices/apiLiteratureSlice";
import AddModalTextLiterature from "../Modals/AddModalTextLiterature";


const TableTextLiterature = () => {
  const dispatch = useDispatch();
  const { currentPage, itemsPerPage, searchQuery } = useSelector(
    (state) => state.table
  );
  const loadingDelete = useSelector((state) => state.apiLiterature.loadingDelete)
  const loadingCreate = useSelector((state) => state.apiLiterature.createTextLit)
  const loading = useSelector((state) => state.apiLiterature.loading)
  const backendBaseUrl = 'https://back.autoshkollaime.com';
  const dataTextLiterature = useSelector((state) => state.apiLiterature.dataTextLirature);
  const [searchInput, setSearchInput] = useState("");



  const filteredData = dataTextLiterature.filter((item) =>
  item.title && item.title.toLowerCase().includes(searchQuery.toLowerCase())
);

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = filteredData.slice(firstIndex, lastIndex);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };



  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };

  useEffect(() => {
    dispatch(fetchDataTextLiterature());
  }, [loadingDelete, loadingCreate, loading]);

  return (
    <div className="p-3">
      <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
        <SearchBar
          searchInput={searchInput}
          handleSearchInputChange={handleSearchInputChange}
          handleSearchSubmit={handleSearchSubmit}
        />
        <div>
          <button className="text-blue-500 mx-2" alt="you can edit here">
            <AddModalTextLiterature
              title="Shto një tekst"
              submitButtonText="Shto tekstin"
              onCancel={handleCloseModal}
              inputLabel1="Emri i testit"
              inputLabel2="Zgjedh Subliteraturen"

            />           
             </button>
        </div>
      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Emri i Tekstit
              </th>
              <th scope="col" className="px-6 py-3 ">
                Teksti
              </th>
              <th scope="col" className="px-6 py-3">
                Emri I Literatures
              </th>
              <th>
                Emri i Kategoris
              </th>
              <th scope="col" className="px-6 py-3">
                Foto
              </th> 
              <th scope="col" className="px-6 py-3">
                Action
              </th>
              
            </tr>
          </thead>
          <tbody>
          <TableItemsTextLiterature
            dataTextLiterature={currentItems}
            baseUrl={backendBaseUrl}
          />
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          filteredData={filteredData}
        />
      </div>
    </div>
  );
};

export default TableTextLiterature;