import React from "react";
import { openModal,closeModal } from "../../store/slices/editModalSlice";
import { useDispatch, useSelector } from "react-redux";
import InfoModal from "../Modals/InfoModal";
import { openModalDelete } from "../../store/slices/deleteModalTextLiteratureSlice";
import DeleteModalTextLiterature from "../Modals/DeleteModal/DeleteModalTextLiterature";
import EditModalTextLiterature from "../Modals/EditModal/EditModalTextLiterature";

const TableItemsTextLiterature = ({ dataTextLiterature, baseUrl}) => {
  const dispatch = useDispatch();
  const editModalState = useSelector((state) => state.editModalTextLiterature); 
 
  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleOpenModal = (id) => {
    dispatch(openModal(id)); 
  };

  const handleEditItem = (id) => {
    dispatch(openModal(id)); 
  };

  const handleDeleteItem = (id) => {
    dispatch(openModalDelete(id));
  };
  return (
    <>
      {dataTextLiterature?.map((item) => (
        <tr
          key={item.id}
          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {item.title}
          </td>
          <td className="px-6 py-4 font-medium text-gray-900 whitespace-normal max-w-[200px] dark:text-white ">
           {item.text.substring(0, 200)}...
          </td>
          <td className="px-6 py-4">{item.literature_name}</td>
          <td className="px-6 py-4">{item.category_name}</td>
          <td className="px-6 py-4">
            <a href={`${baseUrl}/${item.icon}`} target="_blank" rel="noopener noreferrer">
              <img src={`${baseUrl}/${item.icon}`}
                alt="Question"
                className="w-14 h-14 object-cover"
              />
            </a>
          </td>
          <td className="px-6 py-4">
            <button className="text-blue-500 mr-2">
              <InfoModal
                title="Info rreth pyetjes"
                onCancel={handleCloseModal}
                name="Lloji i testit"
                categoryInInfo="Pyetja"
              />
            </button>
            <button className="text-sucess mx-1" onClick={() => handleEditItem(item.id)}>
              <EditModalTextLiterature
                id={item.id} 
                title="Edito një tekst"
                submitButtonText="Edito tekstin"
                onCancel={handleCloseModal}
                titleContent={item.title}
                text={item.text}
                isOpen={editModalState[item.id] === true} 
              />
            </button>
            <button className="text-danger mx-1"  onClick={() => handleDeleteItem(item.id)}>
              <DeleteModalTextLiterature
              id={item.id} 
              onCancel={handleCloseModal}
              />
            </button>
          </td>
        </tr>
      ))}
    </>
  );
};

export default TableItemsTextLiterature;
