import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const deleteModalExamsSlice = createSlice({
  name: "deleteModalExams",
  initialState,
  reducers: {
    openModalDelete: (state, action) => {
      const itemId = action.payload;
      state[itemId] = true;
    },
    closeModalDelete: (state, action) => {
      const itemId = action.payload;
      state[itemId] = false;
    },
  },
});

export const { openModalDelete, closeModalDelete } = deleteModalExamsSlice.actions;
export default deleteModalExamsSlice.reducer;
