import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../../store/slices/editModalDrivingSchoolsSlice";
import { FaPencilAlt } from "react-icons/fa";
import { FaRegCircleXmark } from "react-icons/fa6";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchDataDrivingSchools, updateDataDrivingSchools } from "../../../store/slices/apiDrivingSchoolsSlice";
import { dataCity } from "../../../data/dataTable";

const EditModalDrivingSchools = ({
  id,
  title,
  submitButtonText,
  drivingSchoolName,
  cityName,
  schoolStatus,
  schoolCash,

}) => {
  const dispatch = useDispatch();
  const dataExams = useSelector((state) => state.apiDrivingSchools.data);
  const isOpen = useSelector((state) => state.editModalDrivingSchools[id] === true);


  const [changeSchoolName, setChangeSchoolName] = useState("");
  const [changeCity, setChangeCity] = useState("");
  const [changeStatus, setChangeStatus] = useState("");
  const [changeCash, setChangeCash] = useState("");
  const [schoolIsActive, setSchoolIsActive] = useState(false);
  const [schoolIsActive2, setSchoolIsActive2] = useState(false);
  const [selectedCity, setSelectedCity] = useState(changeCity);
  useEffect(() => {
    dispatch(fetchDataDrivingSchools());
  }, []);


  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption.value);
  };

  const handleSchoolStatusChange = (isChecked) => {
    setSchoolIsActive(isChecked);
  };
  const handleSchoolStatusChange2 = (isChecked) => {
    setSchoolIsActive2(isChecked);
  };

  const handleOpenModal = () => {
    const itemToEdit = dataExams.find((item) => item.id === id);
   
    if (itemToEdit) {
      setChangeSchoolName(itemToEdit.name);
      setChangeCity(itemToEdit.city);
      setChangeStatus(itemToEdit.status);
      setChangeCash(itemToEdit.cash)
      setSchoolIsActive(itemToEdit.status === 1);
      setSchoolIsActive2(itemToEdit.cash === 1);
      setSelectedCity(itemToEdit.city);

    }
    dispatch(openModal(id));
  };


  const handleCloseModal = () => {
    dispatch(closeModal(id));
  };

  const handleEditItem = async (e) => {
    e.preventDefault()
    const updatedData = {
      name: changeSchoolName,
      city: selectedCity,
      status: schoolIsActive ? 1 : 0,
      cash: schoolIsActive2 ? 1 : 0,
    };
    try {
      await dispatch(updateDataDrivingSchools({ id: id, updatedData }));
      toast.success("Te dhenat jan ruajtur me sukses!");
      // handleCloseModal();
    } catch (error) {
      console.log(error)
    }
  };


  return (
    <div>
      <button
        data-modal-target="popup-modal"
        data-modal-toggle="popup-modal"
        onClick={handleOpenModal}
      >
        <FaPencilAlt style={{ width: 20, height: 20 }} />
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
          <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
          <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "420px" }}
          >
            <button
              type="button"
              onClick={handleCloseModal}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
            >
              <FaRegCircleXmark style={{ width: 20, height: 20 }} />
            </button>

            <div className="p-6 text-center">
              <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                {title}
              </h3>
              <form className="space-y-6 text-start" onSubmit={handleEditItem}>
                {drivingSchoolName && (
                  <div>
                    <label
                      htmlFor="drivingSchool"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {drivingSchoolName}:
                    </label>
                    <input
                      type="text"
                      name="drivingSchool"
                      id="drivingSchool"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Shkruaj emrin "
                      value={changeSchoolName}
                      onChange={(e) => setChangeSchoolName(e.target.value)}
                      required
                    />
                  </div>
                )}
              
                {cityName && (
                  <div>
                    <label
                      htmlFor="questionScore"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {cityName}:
                    </label>
                    <Select
                      id="city"
                      name="city"
                      options={dataCity}
                      value={{ value: selectedCity, label: selectedCity }}
                      onChange={handleCityChange}
                    />
                  </div>
                )}

                {schoolStatus && (
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Statusi Autoshkolles:
                    </label>
                    <div className="flex">
                      <button
                        type="button"
                        className={`mr-2 text-sm rounded-lg px-3 py-1 ${schoolIsActive
                            ? "bg-green-500 text-white"
                            : "bg-gray-200 text-gray-700"
                          }`}
                        onClick={() => handleSchoolStatusChange(true)}
                      >
                        Aktiv
                      </button>
                      <button
                        type="button"
                        className={`text-sm rounded-lg px-3 py-1 ${!schoolIsActive
                            ? "bg-red-500 text-white"
                            : "bg-gray-200 text-gray-700"
                          }`}
                        onClick={() => handleSchoolStatusChange(false)}
                      >
                        Pasiv
                      </button>
                    </div>
                  </div>
                )}

                {schoolCash && (
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Me para ne dore:
                    </label>
                    <div className="flex">
                      <button
                        type="button"
                        className={`mr-2 text-sm rounded-lg px-3 py-1 ${schoolIsActive2
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200 text-gray-700"
                          }`}
                        onClick={() => handleSchoolStatusChange2(true)}
                      >
                        Po
                      </button>
                      <button
                        type="button"
                        className={`text-sm rounded-lg px-3 py-1 ${!schoolIsActive2
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200 text-gray-700"
                          }`}
                        onClick={() => handleSchoolStatusChange2(false)}
                      >
                        Jo
                      </button>
                    </div>
                  </div>
                )}

                <div className="flex justify-center">
                  <div className="flex items-center">
                    <button
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      {submitButtonText}
                    </button>

                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={handleCloseModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Anulo
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditModalDrivingSchools;