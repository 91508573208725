import React, { useState } from 'react'
import { FaRegCircleXmark } from "react-icons/fa6";
import { useDispatch } from 'react-redux';
import { deleteQuestionCategoryWithAnswer } from '../../store/slices/apiQuestionCategorySlice';
import EditModalQuestionWithCategory from '../Modals/EditModal/EditModalQuestionWithCategory';
const TableItemsQuestionWithCategory = ({ dataQuestionCategory, baseUrl }) => {
  const dispatch = useDispatch();
  const [item, setItem] = useState()
  const [open, setOpen] = useState(false)
  const handleDeleteItem = (id) => {
    dispatch(deleteQuestionCategoryWithAnswer(id))
  }
  const onClose = () => {
    setOpen(!open)
  }
   
  return (
    <>
      {open &&  <EditModalQuestionWithCategory item={item} onClose={onClose} />}
      {dataQuestionCategory?.map((item) => (
        <tr
          key={item.questionCategory_id}
          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {item.question_text}
          </td>
          <td className="px-6 py-4 font-medium text-gray-900 whitespace-normal max-w-[200px] dark:text-white ">
            {item.category_name}
          </td>
          <td className="px-6 py-4">{item.category_question_name}</td>
          <td className="px-6 py-4">
            <a href={`${baseUrl}/${item.question_icon}`} target="_blank" rel="noopener noreferrer">
              <img src={`${baseUrl}/${item.question_icon}`}
                alt="Question"
                className="w-14 h-14 object-cover"
              />
            </a>
          </td>
          <td className="px-6 py-4 font-medium text-gray-900 whitespace-normal max-w-[200px] dark:text-white ">
            {item.question_score}
          </td>
          <td className="px-6 py-4">


            <button onClick={() => { 
              setOpen(!open) 
              setItem(item)
              }}>
              Edito
            </button>
            <button className="text-danger mx-1"
              onClick={() => handleDeleteItem(item.questionCategory_id)}
            >
              <FaRegCircleXmark style={{ width: 20, height: 20 }} />
            </button>
          </td>
        </tr>
      ))}
    </>
  )
}

export default TableItemsQuestionWithCategory