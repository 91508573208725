import React from "react";
import { TablePackage } from "../../components";

const Package = () => {
  return (
    <div>
      <div className="m-2 md:m-10 p-2 md:p-6 bg-white rounded-3xl">
        <p className="text-xl mb-4 text-left">Lista e Pakove</p>
        <TablePackage />
      </div>
    </div>
  );
};

export default Package;