import jwtDecode from 'jwt-decode';
import React from 'react';
import { Navigate } from "react-router-dom";

function PrivateRoute({ children, roles }) {
  const isAuthenticated = localStorage.getItem('userToken')
  const decodedToken = isAuthenticated && jwtDecode(isAuthenticated) 

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  if (roles && !roles.includes(decodedToken.role)) {
    return <Navigate to="/login" replace />;
  }

  return children



}

export default PrivateRoute;