import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "../../api/axiosInstance";

export const fetchDataTextLiterature = createAsyncThunk("api/fetchDataTextLiterature", async () => {
  try {
    const response = await axiosInstace.get("/literature/getSubliteratureText");
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});


export const createDataTextLiterature = createAsyncThunk(
  "api/createDataTextLiterature",
  async (createDataTextLiterature) => {
    try {
      const response = await axiosInstace.post("/TextLiterature/addTextLiterature", createDataTextLiterature);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);


export const updateDataTextLiterature = createAsyncThunk(
  "api/updateQuestionsCategory",
  async ({ id, updatedData }) => {
    try {
      const response = await axiosInstace.put(`/TextLiterature/editTextLiterature/${id}`, updatedData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteDataTextLiterature = createAsyncThunk(
  "api/deleteTextLiterature",
   async (itemId, thunkAPI) => {
    try {
      await axiosInstace.delete(`/TextLiterature/deleteTextLiterature/${itemId}`);
      return itemId;
    } catch (error) {
      throw new Error(error.response.data.message);

    }
  }
);
const apiTextLiteratureSlice = createSlice({
  name: "apiTextLiterature",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataTextLiterature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataTextLiterature.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataTextLiterature.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createDataTextLiterature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDataTextLiterature.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createDataTextLiterature.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDataTextLiterature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDataTextLiterature.fulfilled, (state, action) => {
        const updatedData = action.payload;
        const index = state.data.findIndex((item) => item.id === updatedData.id);
        if (index !== -1) {
          state.data[index] = updatedData;
        }
        state.loading = false;
      })
      .addCase(updateDataTextLiterature.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDataTextLiterature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
     .addCase(deleteDataTextLiterature.fulfilled, (state, action) => {
        const deletedItemId = action.payload;
        state.data = state.data.filter((item) => item.id !== deletedItemId);
        state.loading = false;
      })
      .addCase(deleteDataTextLiterature.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default apiTextLiteratureSlice.reducer;
