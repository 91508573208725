import React from 'react'
import { TableListQuestionCategory } from '../../components'

const ListQuestionCategory = () => {
  return (
    <div>
      <div className="m-2 md:m-10 p-2 md:p-6 bg-white rounded-3xl">
        <p className="text-xl mb-4 text-left">Pyetjet me kategori</p>
        <TableListQuestionCategory />
      </div>
    </div>
  )
}

export default ListQuestionCategory