import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const fetchDataLiterature = createAsyncThunk(
  "api/fetchDataLiterature",
  async () => {
    try {
      const response = await axiosInstance.get("/literature/getLiterature");
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchDataTextLiterature = createAsyncThunk("api/fetchDataTextLiterature", async () => {
  try {
    const response = await axiosInstance.get("/literature/getLiteratureText");
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});


export const createDataLiterature = createAsyncThunk(
  "api/createDataLiterature",
  async (createDataLiterature) => {
    try {
      const response = await axiosInstance.post(
        "/literature/addLiterature",
        createDataLiterature
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const createDataTextLiterature = createAsyncThunk(
  "api/createDataTextLiterature",
  async (createDataTextLiterature) => {
    try {
      const response = await axiosInstance.post(
        "/literature/addTextLiterature",
        createDataTextLiterature
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const updateDataLiterature = createAsyncThunk(
  "api/updateLiterature",
  async ({ id, updatedData }) => {
    try {
      const response = await axiosInstance.put(
        `/literature/updateLiterature/${id}`,
        updatedData
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteDataLiterature = createAsyncThunk(
  "api/deleteLiterature",
  async (itemId, thunkApi) => {
    try {
      await axiosInstance.delete(`/literature/deleteLiterature/${itemId}`);
      return itemId;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteDataSubliterature = createAsyncThunk(
  "api/deleteDataSubliterature",
  async (itemId, thunkApi) => {
    try {
      await axiosInstance.delete(`/literature/deleteSubLiterature/${itemId}`);
      return itemId;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteDataTextLiterature = createAsyncThunk(
  "api/deleteDataTextLiterature",
  async (itemId) => {
    try {
      await axiosInstance.delete(`/literature/deleteTextLiterature/${itemId}`);
      return itemId;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const updateDataTextLiterature = createAsyncThunk(
  "api/updateTextLiterature",
  async ({ id, updatedData }) => {
    try {
      const response = await axiosInstance.put(
        `/literature/updateTextLiterature/${id}`,
        updatedData
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const apiLiteratureSlice = createSlice({
  name: "apiLiterature",
  initialState: {
    data: [],
    loading: false,
    error: null,
    dataTextLirature: [],
    loadingDataTextLiterature: false,
    errorDataTextLiterature: null,
    loadingUpdate: false,
    loadingDelete: false,
    createTextLit: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createDataTextLiterature.fulfilled, (state) => {
        state.createTextLit = !state.createTextLit
      })
      .addCase(fetchDataTextLiterature.pending, (state) => {
        state.loadingDataTextLiterature = true;
        state.errorDataTextLiterature = null;
      })
      .addCase(fetchDataTextLiterature.fulfilled, (state, action) => {
        state.loadingDataTextLiterature = false;
        state.dataTextLirature = action.payload;
      })
      .addCase(fetchDataTextLiterature.rejected, (state, action) => {
        state.loadingDataTextLiterature = false;
        state.errorDataTextLiterature = action.error.message;
      })
      .addCase(fetchDataLiterature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataLiterature.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataLiterature.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createDataLiterature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDataLiterature.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createDataLiterature.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDataLiterature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDataLiterature.fulfilled, (state, action) => {
          state.loadingUpdate = !state.loadingUpdate
      })
      .addCase(updateDataLiterature.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDataLiterature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDataLiterature.fulfilled, (state, action) => {
        const deletedItemId = action.payload;
        state.data = state.data.filter((item) => item.id !== deletedItemId);
        state.loading = false;
      })
      .addCase(deleteDataLiterature.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDataSubliterature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDataSubliterature.fulfilled, (state, action) => {
        const deletedItemId = action.payload;
        state.data = state.data.filter((item) => item.id !== deletedItemId);
        state.loading = false;
      })
      .addCase(deleteDataSubliterature.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDataTextLiterature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDataTextLiterature.fulfilled, (state, action) => {
          state.loadingDelete = !state.loadingDelete
      })
      .addCase(deleteDataTextLiterature.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDataTextLiterature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDataTextLiterature.fulfilled, (state, action) => {
        state.loading = !state.loading;
      })
      .addCase(updateDataTextLiterature.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export default apiLiteratureSlice.reducer;
