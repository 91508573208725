import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../store/slices/addModalSlice";
import { FaRegCircleXmark } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchDataCategories } from "../../store/slices/apiCategorySlice";
import Select from "react-select";
import { createDataPackage, fetchDataPackage } from "../../store/slices/apiPackageSlice";

const AddModalPackage = ({
  title,
  submitButtonText,
  namePackage,
  packageCategory,
  priceValue,
  adminValue,
  schoolValue
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpen = useSelector((state) => state.addModal.isOpen);

  const [packageName, setPackageName] = useState("");
  const [price, setPrice] = useState("");
  const [adminPrice, setAdminPrice] = useState("");
  const [schoolPrice, setSchoolPrice] = useState("");
  const [month, setMonth] = useState("")
  const [selectedOption, setSelectedOption] = useState(null);
  const categoryOptions = useSelector((state) => state.apiCategory.data);


  useEffect(() => {
    dispatch(fetchDataCategories());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedCategoryId = selectedOption ? selectedOption.value : null;

    const data = {
      name: packageName,
      price: price,
      adminPrice: adminPrice,
      schoolPrice: schoolPrice,
      category_id: selectedCategoryId,
      month: month
    };
    try {
      await dispatch(createDataPackage(data));
      setPackageName("");
      setPrice("");
      setAdminPrice("");
      setSchoolPrice("");
      setSelectedOption(null);
      handleCloseModal();
      setMonth('')
      toast.success("Testi u shtua me sukses !");
      dispatch(fetchDataPackage())
    } catch (error) {
      console.log("Error submitting data:", error);
    }
  };

  const handleOpenModal = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <div>
      <button
        data-modal-target="popup-modal"
        data-modal-toggle="popup-modal"
        onClick={handleOpenModal}
        className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-3 py-2   hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"

      >
        Shto Pako
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
          <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
          <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "360px" }}
          >
            <button
              type="button"
              onClick={handleCloseModal}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
            >
              <FaRegCircleXmark style={{ width: 20, height: 20 }} />
            </button>

            <div className="p-6 text-center">
              <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                {title}
              </h3>
              <form className="space-y-6 text-start" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="input1"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {namePackage}:
                  </label>
                  <input
                    type="text"
                    name="input1"
                    id="input1"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder=""
                    value={packageName}
                    onChange={(e) => setPackageName(e.target.value)}
                    required
                  />
                </div>

                {packageCategory && (
                  <div>
                    <label
                      htmlFor="input2"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {packageCategory}:
                    </label>
                    <Select
                      isClearable
                      name="input2"
                      id="input2"
                      value={selectedOption}
                      onChange={handleOptionChange}
                      options={categoryOptions.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    />
                  </div>
                )}

                {priceValue && (
                  <div>
                    <label
                      htmlFor="input2"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {priceValue}:
                    </label>
                    <input
                      type="text"
                      name="input1"
                      id="input1"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder=""
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      required
                    />
                  </div>
                )}

                {adminValue && (
                  <div>
                    <label
                      htmlFor="input2"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {adminValue}:
                    </label>
                    <input
                      type="text"
                      name="input1"
                      id="input1"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder=""
                      value={adminPrice}
                      onChange={(e) => setAdminPrice(e.target.value)}
                      required
                    />
                  </div>
                )}

                {schoolValue && (
                  <div>
                    <label
                      htmlFor="input2"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {schoolValue}:
                    </label>
                    <input
                      type="text"
                      name="input1"
                      id="input1"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder=""
                      value={schoolPrice}
                      onChange={(e) => setSchoolPrice(e.target.value)}
                      required
                    />
                  </div>
                )}


                <div>
                  <label
                    htmlFor="month"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Muajt:
                  </label>
                  <input
                    type="number"
                    name="month"
                    id="month"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder=""
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    required
                  />
                </div>
                <div className="flex justify-center">
                  <div className="flex items-center">
                    <button
                      data-modal-hide="popup-modal"
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      {submitButtonText}
                    </button>

                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={handleCloseModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Anulo
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default AddModalPackage;



