import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../../store/slices/editModalExamsSlice";
import { FaPencilAlt } from "react-icons/fa";
import { FaRegCircleXmark } from "react-icons/fa6";
import Select from "react-select";
import {fetchDataExams, updateDataExams} from "../../../store/slices/apiExamsSlice"
import { fetchDataCategories } from "../../../store/slices/apiCategorySlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditModalExams = ({
  id,
  title,
  submitButtonText,
  examsName,
  examsCategoryId,
  premiumStatus
}) => {
  const dispatch = useDispatch();
  const dataExams = useSelector((state) => state.apiExams.data);
  const categoryOptions = useSelector((state) => state.apiCategory.data);
  const isOpen = useSelector((state) => state.editModalExams[id] === true);


  const [changeExamsName, setChangeExamsName] = useState("");
  const [changeExamsCategory, setChangeExamsCategory] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [showId, setShowId] = useState("");
  const [premiumExams, setPremiumExams] = useState(false);




  useEffect(() => {
    dispatch(fetchDataExams());
    dispatch(fetchDataCategories()); 
  }, []);

  const handlePremiumExams = (isChecked) => {
    setPremiumExams(isChecked);
  };
 
  const handleOpenModal = () => {
    const itemToEdit = dataExams.find((item) => item.id === id);

    if (itemToEdit) {
      setChangeExamsName(itemToEdit.name);
      setChangeExamsCategory(itemToEdit.categoryId);
      setShowId(itemToEdit.categoryName);
      setPremiumExams(itemToEdit.isPremium === 1);
    }
    dispatch(openModal(id));
  };


  const handleCloseModal = () => {
    dispatch(closeModal(id));
  };

  const handleOptionChange = (selectedOptions) => {
    setSelectedOption(selectedOptions);
  };

  const handleEditItem = () => {
    const selectedCategoryId = selectedOption ? selectedOption.value : null; 

    const updatedData = {
      name: changeExamsName,
      categoryId: selectedCategoryId,
      isPremium: premiumExams ? 1 : 0
    };

    dispatch(updateDataExams({ id: id, updatedData }));
    toast.success("Te dhenat jan ruajtur me sukses!");
    dispatch(fetchDataExams())
    handleCloseModal();
  };

  return (
    <div>
      <button
        data-modal-target="popup-modal"
        data-modal-toggle="popup-modal"
        onClick={handleOpenModal}
      >
        <FaPencilAlt style={{ width: 20, height: 20 }} />
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
          <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
          <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "360px" }}
          >
            <button
              type="button"
              onClick={handleCloseModal}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
            >
              <FaRegCircleXmark style={{ width: 20, height: 20 }} />
            </button>

            <div className="p-6 text-center">
              <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                {title}
              </h3>
              <form className="space-y-6 text-start" onSubmit={handleEditItem}>
              <div>
                  <label
                    htmlFor="examsName"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {examsName}:
                  </label>
                  <input
                    type="text"
                    name="examsName"
                    id="examsName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder=""
                    value={changeExamsName}
                    onChange={(e) => setChangeExamsName(e.target.value)}
                    required
                  />
                </div>

                {examsCategoryId && (
                  <div>
                    <label
                      htmlFor="examsCategoryId"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {examsCategoryId}:
                      <div className="d-flex justify-content-start text-gray-400" >
                        <p className=" font-normal ">
                         Kategoria e zgjedhur aktuale: <br /> <span className="text-red-400 font-light ">{showId}</span>
                        </p>
                      </div>
                    </label>
                    <Select
                      isClearable
                      name="examsCategoryId"
                      id="examsCategoryId"
                      value={selectedOption}
                      onChange={handleOptionChange}
                      options={categoryOptions.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    />
                  </div>
                )}

                {premiumStatus && (
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Premium: 
                    </label>
                    <div className="flex">
                      <button
                        type="button"
                        className={`mr-2 text-sm rounded-lg px-3 py-1 ${
                          premiumExams
                            ? "bg-green-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handlePremiumExams(true)}
                      >
                        Po
                      </button>
                      <button
                        type="button"
                        className={`text-sm rounded-lg px-3 py-1 ${
                          !premiumExams
                            ? "bg-red-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handlePremiumExams(false)}
                      >
                        Jo
                      </button>
                    </div>
                  </div>
                )}

                <div className="flex justify-center">
                  <div className="flex items-center">
                    <button
                      data-modal-hide="popup-modal"
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      {submitButtonText}
                    </button>

                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={handleCloseModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Anulo
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditModalExams;