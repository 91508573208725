import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../store/slices/editModalSlice";
import { openModalDelete } from "../../store/slices/deleteModalUsersSlice";
import InfoModal from "../Modals/InfoModal";
import DeleteModalUsers from "../Modals/DeleteModal/DeleteModalUsers";
import EditModalUsers from "../Modals/EditModal/EditModalUsers";
import { fetchDataCategories } from "../../store/slices/apiCategorySlice";
import Select from "react-select";
import { activateGuestFromAdmin, getActiveCategoryUserId, selectActiveCategoryUser } from "../../store/slices/authSlice";
import { getCurrentDateTime, getFutureDateTimeDay, formatDateString, isUserActive } from "../../util/time";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const TableItemsUsers = ({ dataUsers }) => {
  const dispatch = useDispatch();
  const activeCategoryUser = useSelector(selectActiveCategoryUser)
  const editModalState = useSelector((state) => state.editModalUsers);
  const [openActiveModal, setOpenActiveModal] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [day, setDay] = useState("")
  const [id, setId] = useState('')
  const dataCategories = useSelector((state) => state.apiCategory.data);
  const [activity, setActivity] = useState(false)


  const onSubmit = (e) => {
    e.preventDefault()

    const selectedQuestionCategoryIds = selectedCategories.map(
      (option) => option.value
    );

    const data = {
      userId: id,
      catId: JSON.stringify(selectedQuestionCategoryIds),
      activeStart: getCurrentDateTime(),
      activeEnd: getFutureDateTimeDay(day),
    }
    try {
      dispatch(activateGuestFromAdmin(data))
      setDay('')
      setSelectedCategories([])
      toast.success("Kandidati u aktivizua me sukses")
    } catch (error) {
      console.log(error)
    }
  }

  const getActiveCategoryId = (userId) => {
    setActivity(!activity)
    try {
      dispatch(getActiveCategoryUserId(userId))
    } catch (error) {
      console.log(error)
    }
  }
  const handleOptionChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
  };
  const handleEditItem = (id) => {
    dispatch(openModal(id));
  };

  const handleDeleteItem = (id) => {
    dispatch(openModalDelete(id));
  };

  useEffect(() => {
    dispatch(fetchDataCategories())
  }, [])

console.log(activeCategoryUser)
  return (
    <>

      {activity && (
        <div className="modal  fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50">

          <div className="modal-content mx-auto my-10 p-6 bg-white w-1/2 rounded">


            <div>
              <div className='flex justify-between'>
                <p>Kategori Aktive</p>
                <span onClick={() => setActivity(!activity)} className="close cursor-pointer">&times;</span>
              </div>

              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Kategoria
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Koha e fillimit
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Koha e mbarimit
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Statusi
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Menyra e Pageses
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      activeCategoryUser.map(item => {
                        return (
                        <tr key={item.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {item.name}
                          </th>
                          <td className="px-6 py-4">
                            {formatDateString(item.activeStart)}
                          </td>
                          <td className="px-6 py-4">
                            {formatDateString(item.activeEnd)}
                          </td>
                          <td className="px-6 py-4">
                            {isUserActive(item.activeStart, item.activeEnd) ? "Aktiv" : "Skaduar"}
                          </td>
                          <th className="px-6 py-4">
                              {item.statusCash === 1 ? "Permes Autoshkolles" : "Me para ne dorë" }
                          </th>
                        </tr>
                      )}
                      )
                    }
                  </tbody>
                </table>
              </div>

            </div>

          </div>
        </div>

      )}
      {
        openActiveModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
            <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
            <div
              className="relative bg-white rounded-lg shadow-lg"
              style={{ width: "360px" }}
            >

              <div className="p-6 text-center">
                <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                  Aktivizo
                </h3>
                <form className="space-y-6 text-start" onSubmit={onSubmit}>

                  <div>
                    <label
                      htmlFor="day"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Ditet:
                    </label>
                    <input
                      type="number"
                      name="day"
                      id="day"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Ditet"
                      value={day}
                      onChange={(e) => setDay(e.target.value)}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="selectedCategories"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Kategoria:
                    </label>
                    <Select
                      isMulti
                      name="selectedCategories"
                      id="selectedCategories"
                      value={selectedCategories}
                      onChange={handleOptionChange}
                      options={dataCategories.map((option) => ({
                        value: option.id,
                        label: `${option.name}`,
                      }))}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    />
                  </div>



                  <div className="flex justify-center">
                    <div className="flex items-center">
                      <button
                        data-modal-hide="popup-modal"
                        type="submit"
                        className="text-white bg-blue-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 mr-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                      >
                        Aktivizo
                      </button>

                      <button
                        onClick={() => setOpenActiveModal(!openActiveModal)}
                        data-modal-hide="popup-modal"
                        type="button"
                        className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                      >
                        Anulo
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

        )
      }
      {dataUsers.map((item) => {
        return (
          <tr
            key={item.id}
            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
          >
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {item.name}
            </td>
            <td className="px-6 py-4">{item.email}</td>
            <td className="px-6 py-4">{item.phoneNumber}</td>
            <td className="px-6 py-4">{item.driveSchoolName}</td>
            <td className="px-6 py-4">{item.role === "user" ? "Autoshkoll" : item.deviceId}</td>
            <td className="px-6 py-4">
              <button onClick={() => {
                setOpenActiveModal(!openActiveModal)
                setId(item.id)
              }} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-[20px]">
                Aktivizo
              </button>
            </td>
            <td className="px-6 py-4">
              <button onClick={() => {
                getActiveCategoryId(item.id)
              }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-[20px]">
                Shiko Aktivizimet
              </button>
            </td>

            <td className="px-6 py-4 w-40">
              <button className="text-blue-500 mr-2">
                <InfoModal
                  title="Info rreth testit"
                  name="Emri i testit"
                  categoryInInfo="Kategoria e testit"
                />
              </button>
              <button className="text-sucess mx-1" onClick={() => handleEditItem(item.id)}>
                <EditModalUsers
                  id={item.id}
                  title="Ndrysho Paswordin"
                  submitButtonText="Aktivizo"
                  durationName="Kohezgjatja"
                  subscriptionStatus="A eshte abonuar"
                  isOpen={editModalState[item.id] === true}
                />
              </button>
              <button className="text-danger mx-1" onClick={() => handleDeleteItem(item.id)}>
                <DeleteModalUsers
                  id={item.id}
                />
              </button>
            </td>
          </tr>
        )
      })}
    </>
  );
};

export default TableItemsUsers;